/**
 *
 */
// 需要权限校验的路由地址 requiresUserAuthPath 是路由地址数组【不能加/】
// import { requiresUserAuthPath } from '~/middleware/check-login.js'

export default function ({ errorCode, url, noRedirect }) {
  // 接口地址api 白名单
  const whiteRuleUrl = ['/member/api/info/simple/'] //  不需要白监听的接口请求
  if (errorCode == 10001 && !whiteRuleUrl.includes(url)) {
    const redirectUrl = `/login/`
    // 是不是存在需要做校验的页面
    if (window && noRedirect !== true) {
      window.location.href = redirectUrl
    }
  }
}
