import { $get, $post } from '../base'

/**
 * @method 图片上传后审核
 */
export function httpImageAudit(params) {
  return $post(`/edit/api/image_audit/`, params)
}

/**
 * @method 获取上传图片到七牛的token
 */
export function httpGetQiniuToken(param) {
  return $get('/images/qiniu/uptoken/', param)
}

/**
 * @method 获取上传文件到七牛的token
 */
export function httpGetUploadQiniuToken(params) {
  return $get(`/edit/api/upload/qiniu_token/${params.pid}/`, params)
}

/**
 * 获取用户现有套餐版本
 */
export function httpMemberPackageInfo(params) {
  return $get('/plugin/api/v1/member_package/info/', params)
}

export function httpDownLoadInfo(params) {
  return $get('/report/api/download/infos/', params)
}
