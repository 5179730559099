// 操作cookie
export function set(c_name, value, expiredays, beforeDomain) {
  let exdate
  const domain = getParseDomain(beforeDomain)
  // expiredays 可以是Number类型的过期天数
  // 也可以是String类型的日期字符串
  // 还可以是Date类型的
  if (expiredays && expiredays instanceof Date) {
    // expiredays是Date类型
    exdate = expiredays
  } else if (expiredays && isDate(expiredays)) {
    // expiredays是日期字符串
    exdate = new Date(expiredays).toUTCString()
  } else {
    // expiredays为Nubmer类型或者未设置
    const newDate = new Date()
    expiredays =
      !expiredays || expiredays != Number(expiredays) ? 1 : expiredays
    newDate.setDate(newDate.getDate() + expiredays)
    exdate = newDate.toUTCString()
  }
  if (typeof window !== 'undefined') {
    document.cookie = `${c_name}=${escape(
      value
    )};expires=${exdate};path=/;domain=${domain}`
  }
}

export function get(c_name) {
  if (typeof window !== 'undefined' && document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(`${c_name}=`)
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1
      let c_end = document.cookie.indexOf(';', c_start)
      if (c_end == -1) c_end = document.cookie.length
      return unescape(document.cookie.substring(c_start, c_end))
    }
  }
  return ''
}
export function del(name) {
  const exp = new Date()
  exp.setTime(exp.getTime() - 1)
  const cval = get(name)
  const domain = getParseDomain()
  if (cval != null && typeof window !== 'undefined') {
    document.cookie = `${name}=${cval};expires=${exp.toUTCString()};path=/;domain=${domain}`
  }
}
// 获取设置cookie的domain（和后端设置逻辑保持统一）
export function getParseDomain(beforeDomain) {
  const hostname = location.hostname.toLocaleLowerCase()
  let domain = beforeDomain || hostname
  const reWenjuan = /.wenjuan.com$/
  const reLocalhost = /^(192.168.)|^(127.0.0.)/
  if (reWenjuan.test(domain)) {
    domain = '.wenjuan.com'
  } else if (reLocalhost.test(domain)) {
    // domain = domain;
  } else if (domain == 'localhost') {
    domain = hostname
  } else {
    domain = `.${domain}`
  }
  return domain
}

// 验证字符串str是否为日期格式的字符串
function isDate(str) {
  const reg = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/
  if (!str) return false
  if (
    reg.test(str) &&
    RegExp.$2 <= 12 &&
    RegExp.$3 <= 31 &&
    RegExp.$4 <= 23 &&
    RegExp.$5 <= 59 &&
    RegExp.$6 <= 59
  ) {
    return true
  }
  return false
}
