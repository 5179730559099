import { Parser } from 'htmlparser2/lib/index'
import Vue from 'vue'
import { httpMemberPackageInfo } from '~/api/common.js'
/** curry function */
export const compose =
  (...argsTask) =>
  (...args) =>
    argsTask.reduceRight((p, n, i) => (argsTask.length === i + 1 ? n(...p) : n(p)), args)
/**
 * @wei。luo
 * 柯里化构造器
 *@param {Array<any entends keyof (string | object)>} props  属性构造与默认值
 *@param {Array<any entends keyof (string | object) exnteds typeof props >}  values 赋值构造器
 * */
export const curryFactory =
  (...props) =>
  (...values) => {
    let unSize = 0
    const options = props.reduce((p, n, i) => {
      if (typeof n === 'object') {
        p = Object.assign(n, p)
        unSize++
      } else {
        p[n] = values[i]
      }
      return p
    }, {})
    const exntedsOptions = values?.[props.length - unSize]
    return {
      ...options,
      ...exntedsOptions,
    }
  }

/**
 * @desc  函数防抖---“立即执行版本” 和 “非立即执行版本” 的组合版本
 * @param  func 需要执行的函数
 * @param  wait 延迟执行时间（毫秒）
 * @param  immediate---true 表立即执行，false 表非立即执行
 **/
export function debounce(func, wait, immediate) {
  let timer

  return function () {
    const context = this
    const args = arguments

    if (timer) clearTimeout(timer)
    if (immediate) {
      const callNow = !timer
      timer = setTimeout(() => {
        timer = null
      }, wait)
      if (callNow) func.apply(context, args)
    } else {
      timer = setTimeout(function () {
        func.apply(context, args)
      }, wait)
    }
  }
}

/**
 * @wei。luo
 * mini节流
 * @param {*} taskFn
 * @param {*} timer
 * @param  {...any} params
 * type throttle = (task: (any: typeof params, timer: Number, ...params : Array<Object>) => any
 */
export const throttle = function (taskFn, timer = 800, ...params) {
  if (taskFn.$process) return
  taskFn.$process = true
  setTimeout((_) => {
    taskFn.$process = undefined
  }, timer)
  return taskFn(...params)
}

/**
 * @wei。luo
 *  性能优化抖流，可以作为回调参数
 * <T, U in keyof typof T>(key: (...keys: U ), timer:Number) => (...parmas: T) => any => any
 */
export const curryDalay = function (taskFn, timer = 300, callback) {
  return function (...params) {
    if (taskFn.$beDaley) clearTimeout(taskFn.$beDaley)
    return new Promise((resolve) => {
      taskFn.$beDaley = setTimeout((_) => {
        callback?.(taskFn.call(this, ...params))
        resolve(taskFn?.call?.(this, ...params))
        taskFn.$beDaley = undefined
      }, timer)
    })
  }
}

/**
 * @wei。luo
 * 获取body可视区操作
 */
export const getClientWidth = () => {
  try {
    if (window) {
      return { innerWidth: window.innerWidth, clientWidth: document.body.clientWidth }
    }
  } catch (e) {
    return Promise.reject(e)
  }
}
/**
 * @wei。luo
 * 获取类型
 * @param {any} type
 * @returns  String
 */
export const getType = (type) => Object.prototype.toString.call(type).slice(8, -1).toLowerCase()

export const mergeObject = (originData, deData) => {
  Object.keys(deData).forEach((key) => {
    const from = deData[key]
    const to = originData[key]
    if (getType(from) === 'object') {
      to ? mergeObject(to, from) : mergeObject((originData[key] = {}), from)
    } else if (getType(from) === 'array') {
      originData[key] = [...originData[key], ...from]
    } else {
      originData[key] = from
    }
  })
}
/**
 * @author wei.luo
 *  前后对比数据变化执行，优化前端页面渲染
 * @param {Array |String} pre 前一次数据
 * @param {Array |String} val 后一次数据
 * @param {Boolean} isMobx 是否mobx化
 * @param {Function} taskFn 回调或者执行函数
 * */
export const useMemo =
  (pre, result, isMobx = false) =>
  (val, taskFn, ...params) => {
    let isQuerse = false
    if (Array.isArray(val) && Array.isArray(pre)) {
      isQuerse = !val.some((v, i) => v !== pre[i])
    } else {
      isQuerse = pre === val
    }
    if (isQuerse) return result
    pre = val
    result = taskFn(...params)
    return isMobx ? JSON.parse(JSON.stringify(result)) : result
  }

/**
 * @author wei.luo
 * 类似memo 作用一致,但是不具备mem的偏函数功能，不具备回调作用域
 */
export const useCallback = (cval, task, ...params) => {
  const [pre, result] = [task.preUseCallback, task.reslutUseCallback]
  let isQuerse = false
  if (Array.isArray(cval) && Array.isArray(pre)) {
    isQuerse = cval.some((v, i) => v !== pre[i])
  } else {
    isQuerse = pre === cval
  }
  if (isQuerse) return result
  task.preUseCallback = task(...params)
  // eslint-disable-next-line no-return-assign
  return (task.reslutUseCallback = task(...params))
}

/**
 * @param {object | Array} obj  对象| 数组 移除指定的数据
 * @param {...String} 需要移除的属性
 * @returns {Object}
 *  */
export const removeEmpty = (obj, ...nameList) => {
  // 过滤数组
  if (Array.isArray(obj)) {
    return obj.filter((name) => !nameList.includes(name) || name === 0 || !!name)
  }
  // 过滤对象
  return Object.keys(obj).reduce((p, n) => {
    if ((obj[n] === 0 || obj[n]) && !nameList.includes(n)) {
      p[n] = obj[n]
    }
    return p
  }, {})
}

/**
 *
 * @returns 滚轴函数
 */
export const onScroll = (dom = '') => {
  const {
    scrollTop = 0,
    clientHeight = 0,
    scrollHeight,
    offsetHeight,
  } = dom ? document.querySelector(dom) : document.documentElement || document.body || {}
  return {
    offsetHeight,
    scrollTop,
    clientHeight,
    scrollHeight,
    padHeight: scrollHeight - scrollTop - clientHeight,
  }
}
/**
 *
 * @param {String | object} target  目标对象DOM 或者 选择器
 * @param {String | Object} wrapDom  容器选择器
 */
export const scrollTo = (target, wrapDom) => {
  let number = 0
  // 取目标
  if (target) {
    number =
      typeof target === 'string'
        ? (document.querySelector(this.target) || {})?.offsetTop
        : typeof target === 'object'
        ? target?.offsetTop
        : 0
  }
  // 取容器
  const wrap = wrapDom ? document.querySelector(wrapDom) || document.documentElement : document.documentElement
  wrap.scrollTop = number
}

/**
 * @wei
 * @desc 优化操作映射源
 * @param {function} callback
 * @param {Array<HTMLDocument> | string} tagDom
 * @param {Obejct<{
 *    threshold:Array  // 临界点位置
 *    [key]?!: any
 * }>} options
 * @returns observer
 */
export const createObserver = (callback, options) => {
  if (typeof callback === 'object') {
    options = callback
    callback = null
  }
  const mapList = new WeakMap()
  let current = null
  const thunk = (observer) => {
    // 关闭watch
    observer.close = () => {
      observer.disconnect()
    }
    observer.add = (dom) => {
      if (!dom) return (current = null)
      observer.observe(dom)
      current = dom
      mapList.set(dom, null)
      return observer
    }
    observer.then = (taskFc) => {
      if (!current) return
      mapList.set(current, taskFc)
      return observer
    }
    observer.remove = (dom) => {
      observer.unobserve(dom)
      mapList.delete(dom)
      return observer
    }
    observer.finally = function (taskFunction) {
      mapList.finally = taskFunction
      return observer
    }
    observer.show = function () {
      console.log(mapList, 'mapList')
      return observer
    }
    observer.map = function (Doms) {
      const __list__ = Array.isArray(Doms) ? Doms : Array.from(Doms)
      __list__.forEach((item) => observer.add(item))
      return observer
    }
  }
  // 默认配置
  const __options__ = {
    threshold: [0, 0.1, 0.99],
    type: 'intersection',
    ...options,
  }
  // 构造器
  const __ovserver__ = {
    intersection: IntersectionObserver,
    resize: ResizeObserver,
    mutation: MutationObserver,
  }[__options__.type]
  try {
    // eslint-disable-next-line no-throw-literal
    if (!__ovserver__) throw { errorMessage: 'createObserver:构造函数不存在' }
    const observer = new __ovserver__((entries) => {
      entries.forEach((item) => {
        mapList.get(item.target)?.(item.isIntersecting || item.contentRect, item)
      })
      typeof callback === 'function' && callback(entries)
      mapList.finally && mapList.finally?.(entries)
    }, __options__)
    thunk(observer)
    // 返回对象
    return Object.create(observer)
  } catch (e) {
    console.error(e.errorMessage || e)
  }
}

// 改变对象中key 的下划线为单驼峰法
export const replaceKeysTocase = (obj) => {
  const reEge = /_\w/g
  return Object.keys(obj).reduce((p, n) => {
    let [keyName, value] = [n, obj[n]]
    if (Array.isArray(value)) {
      value = value.map(replaceKeysTocase)
    } else if (typeof value === 'object') {
      value = replaceKeysTocase(value)
    }
    if (keyName.includes('_')) {
      keyName = keyName.replace(reEge, (name) => name.slice(-1).toUpperCase())
    }
    p[keyName] = value
    return p
  }, {})
}
// 获取 cookie
export const getCookie = (name) => {
  if (typeof window !== 'undefined' && document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(`${name}=`)
    if (c_start != -1) {
      c_start = c_start + name.length + 1
      let c_end = document.cookie.indexOf(';', c_start)
      if (c_end == -1) c_end = document.cookie.length
      return decodeURIComponent(document.cookie.substring(c_start, c_end))
    }
  }
  return ''
}

/**
 * 解析HTML字符串到指定节点数据，单向链表
 * @param {string} source
 * @param {{
 *  includeTag: RegExp;
 *  excludeTag: RegExp;
 * }} opt
 *    includeTag：包含的标签名，默认全部标签；
 *    excludeTag：不包含的标签名，默认全部匹配
 * @returns {Array<{
 *  tag: string;
 *  attrs: Array<string>;
 *  text: string;
 *  prev: ElNodes
 * }>} ElNodes
 *  标签节点链表。
 *    tag表示标签名；保留一个_blank类型作为纯文本，即无标签的文本
 *    attrs表示标签上的属性（属性项是个数组=>[属性名, 属性值, 属性的引号类型]）；
 *    text表示标签文本；
 *    prev表示当前节点的父节点，会一直找到祖先节点
 */
export function parseHTML(source, { includeTag = /.*/, excludeTag = /\s/ } = {}) {
  const ElNodes = []
  // 找出最近一个打开的节点
  const currentNode = () => {
    for (let i = ElNodes.length - 1; i >= 0; i--) {
      if (ElNodes[i].open === true) {
        return [ElNodes[i], i]
      }
    }
    return [ElNodes[ElNodes.length - 1], ElNodes.length - 1]
  }

  const parse = new Parser({
    onopentagname(name) {
      const emptyNode = {
        tag: name,
        attrs: [],
        text: '',
        open: true, // 只做临时状态，在close tag时被去除，用作标记tag的打开状态
      }
      const [node] = currentNode()
      if (node?.open === true) {
        emptyNode.prev = node
      }

      if (includeTag.test(name) && !excludeTag.test(name)) {
        ElNodes.push(emptyNode)
      }
    },
    onattribute(name, value, quote) {
      const [node] = currentNode()
      if (node) {
        node.attrs.push([name, value, quote])
      }
    },
    ontext(data) {
      const [node] = currentNode()
      if (node) {
        node.text = data
      } else {
        ElNodes.push({
          tag: '_blank',
          text: data,
        })
      }
    },
    onclosetag() {
      const [node] = currentNode()
      if (node) {
        delete node.open
      }
    },
  })
  parse.parseComplete(source)
  return ElNodes
}

/**
 * 提取HTML字符串内指定标签的文本(innerText)，无标签的文本会也会被返回
 * @param {string} html
 * @param {{
 *  includeTag: RegExp;
 *  excludeTag: RegExp;
 * }} opt
 *    includeTag：包含的标签名，默认全部标签；
 *    excludeTag：不包含的标签名，默认/(img|audio|video)/
 * @returns
 */
export function HTMLToPlainText(html, { includeTag, excludeTag = /(img|audio|video)/ } = {}) {
  const elNodes = parseHTML(html, { includeTag, excludeTag })
  return elNodes.map((node) => node.text).join('')
}

// 区分浏览器类型
export function distinguishUserAgentUtils(userAgent) {
  if (typeof window !== 'undefined') {
    const ua = userAgent || window.navigator.userAgent
    return {
      isEdge: ua.includes('Edge'),
      isFF: ua.includes('Firefox'),
      isOpera: ua.includes('Opera'),
      isBB: ua.includes('BlackBerry'),
      isChrome: ua.includes('Chrome'),
      isMaxthon: ua.includes('Maxthon'),
      isFeishu: ua.includes('Lark'),
      isIos: /(iPhone|iPad|iPod|iOS)/i.test(ua),
      isSafari: ua.includes('Safari') && !ua.includes('Chrome'),
      isIE: !!window.ActiveXObject || 'ActiveXObject' in window,
      isMobile: /(iPhone|ipad|iPod|Opera Mini|Android.*Mobile|NetFront|PSP|BlackBerry|Windows Phone)/gi.test(ua),
      isWeixin: ua.toLowerCase().match(/MicroMessenger/i) == 'micromessenger',
      isWeibo: ua.toLowerCase().match(/WeiBo/i) == 'weibo',
      isWxwork: ua.toLowerCase().match(/wxwork/i) == 'wxwork',
      isQQ: ua.toLowerCase().match(/QQ/i) == 'qq',
      isAndroidApp: ua.toLowerCase() == 'android_app/1.0.0',
      isAndroid:
        (ua.toLowerCase().match(/android/i) == 'android' || ua.toLowerCase().match(/adr/i) == 'adr') &&
        ua.toLowerCase() != 'android_app/1.0.0',
      // ios android 客户端
      isNative:
        ua.toLowerCase().match(/android_app\//i) == 'android_app/' ||
        ua.toLowerCase().match(/wenjuan\//i) == 'wenjuan/',
      isIosApp: ua.toLowerCase().match(/wenjuan\//i) == 'wenjuan/',
      isBaiying: ua.toLowerCase().match(/Baiying/i) == 'baiying',
      isDingding: ua.includes('DingTalk'),
      isTencentWemeet: ua.toLowerCase().match(/tencent_wemeet/i) == 'tencent_wemeet',
      isMac: /macintosh|mac os x/i.test(ua),
      isPWA: window?.matchMedia('(display-mode: minimal-ui)').matches,
    }
  }
  return {}
}

export function _throttle(fn, time) {
  var timer = null
  var isTiming = false

  return function () {
    if (!isTiming) {
      isTiming = true
      timer = setTimeout(function () {
        fn()
        clearTimeout(timer)
        isTiming = false
      }, time)
    }
  }
}

export function checkUserAgent() {
  const ua = window.navigator.userAgent.toLowerCase()
  return {
    isWxwork: ua.includes('wxwork'),
    isWeixin: ua.includes('micromessenger'),
    isQQ: ua.includes('qq'),
    // isMobile: ua.includes('mobile')
    isMobile: typeof window.orientation != 'undefined',
  }
}

//  6进制 转为 RGBA
export const hexToRgba = (hex = '#fff', opacity = 1) => {
  if (hex != '' && hex.startsWith('#') && (hex.length == 4 || hex.length == 7)) {
    if (hex.length == 4) {
      const [s, r, g, b] = hex
      hex = `${s}${r}${r}${g}${g}${b}${b}`
    }
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5), 16)
    return `rgba(${r},${g},${b},${opacity})`
  }
  return 'colr is error'
}

// 判断变量是否为空
export function isNotEmpty(obj) {
  if (typeof obj === 'undefined' || obj == null) {
    return false
  }
  if (typeof obj === 'function') {
    return true
  }
  if (obj.constructor == Number) {
    if (obj) {
      return true
    }
    return false
  }
  if (typeof obj === 'string') {
    if (obj !== '') {
      return true
    }
    return false
  }
  return Object.keys(obj).length > 0
}

/**
 * 前端下载文件二进制数据到本地
 * @param {Blob} source 必须是Blob对象，一般下载情况都发生在接口请求后，只需要在接口config里标明 responseType: 'blob'即可
 * @param {string} fileName 本地文件的名称，要带上后缀名，一般从接口返回的头信息取
 * @returns
 */
export function downloadFile(source, fileName) {
  try {
    const a = document.createElement('a')
    const blobURL = URL.createObjectURL(source)
    a.download = fileName
    a.href = blobURL
    a.click()
    URL.revokeObjectURL(blobURL)
  } catch (error) {
    return new Error(error)
  }
}

// 身份证校验
export function idNumCheck(str) {
  const _IDRe18 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  const _IDre15 = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/
  if (_IDRe18.test(str) || _IDre15.test(str)) {
    return true
  }
  return false
}

export function getShortMobile(mobile) {
  if (!mobile) return ''
  return `${mobile.substr(0, 3)}****${mobile.substr(-4)}`
}

// localStorage存储
export function setLocalStorage(key, val) {
  localStorage.setItem(key, val)
}

// localStorage获取
export function getLocalStorage(key) {
  return localStorage.getItem(key)
}

// 获取url中的参数
export function getQueryString(param) {
  const reg = new RegExp('(^|&)' + param + '=([^&]*)(&|$)')
  const r = window.location.search.substr(1).match(reg)
  if (r != null) {
    return unescape(r[2])
  }
  return null
}

// 获取url中的所有参数，返回对象
export function getQueryParams(str) {
  const url = str || window.location.search
  const params = {}
  if (url.includes('?')) {
    const str = url.substring(url.indexOf('?') + 1)
    const strs = str.split('&')
    for (let i = 0; i < strs.length; i++) {
      params[strs[i].split('=')[0]] = decodeURIComponent(strs[i].split('=')[1])
    }
  }
  return params
}

// 驼峰转中横线
export function convertToKebabCase(str) {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}

// 用户套餐版本的枚举，按照数组index升序
export const MEMBER_PKG_TYPE_ENUM = [
  'free', // 后端无此字段，前端定义
  'advance_version',
  'enterprise_version',
  'enterprise_ultimate_version',
  'enterprise_ultimate_free_version',
]

/**
 * 包装packageDialog需要的所有参数
 * @param {*} PKGList 所有套餐信息列表
 * @param {*} memberPKG 当前用户套餐信息
 * @param {*} memberPKGType 当前用户套餐版本
 * @param {*} nextPKGType 需要升级的套餐版本，如果省略，则默认取下一个版本
 * @returns
 */
export function dialogInit(PKGList, memberPKG, memberPKGType, nextPKGType) {
  const i = MEMBER_PKG_TYPE_ENUM.findIndex((type) => type === memberPKGType) || 0
  const nextType = nextPKGType || MEMBER_PKG_TYPE_ENUM[i + 1]
  let packageType = ''

  if (nextType === 'enterprise_version') {
    packageType = 'enterprise'
  }
  if (nextType === 'enterprise_ultimate_version') {
    packageType = 'ultimate'
  }

  return {
    packages: PKGList,
    selPackage: PKGList[nextType],
    curPackageData: memberPKG,
    packageType,
  }
}

// 手机打*号
export function hideMobile(mobile) {
  if (!mobile) return ''
  return `${mobile.substr(0, 3)}****${mobile.substr(-4)}`
}

/**
 * 创建页面元素
 * @param { string } eleName 标签名称
 * @param { array } attrList 元素属性列表
 * @param { string } attrList.key 元素属性名称
 * @param { string } attrList.val 元素属性值
 */
export function createEle(eleName, attrList) {
  const ele = document.createElement(eleName)
  attrList.forEach((item) => {
    ele.setAttribute(item.key, item.val)
  })
  return ele
}

/**
 * 充值
 * @param { string } orderId
 */
export function recharge(orderId) {
  const form = createEle('form', [
    { key: 'action', val: '/member/payredirect/' },
    { key: 'method', val: 'post' },
    { key: 'target', val: '_blank' },
  ])
  const orderIdInput = createEle('input', [
    { key: 'type', val: 'hidden' },
    { key: 'name', val: 'order_id' },
    { key: 'value', val: orderId },
  ])
  const xsrfInput = createEle('input', [
    { key: 'type', val: 'hidden' },
    { key: 'name', val: '_xsrf' },
    { key: 'value', val: getCookie('_xsrf') },
  ])

  form.appendChild(orderIdInput)
  form.appendChild(xsrfInput)
  document.body.appendChild(form)

  setTimeout(() => {
    form.submit()
    form.remove()
  }, 100)
}

// 校验规则函数
export const checkRules = function ({ rule = '', value = '' } = {}) {
  const checkList = {
    邮箱: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
    手机: /^1[3-9]\d{9}$/,
    端口: /^([0-9]|[1-9]\d|[1-9]\d{2}|[1-9]\d{3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/,
  }
  return checkList[rule] && checkList[rule].test(value)
}
// 移除DOM
export function removeMeta(name, callback) {
  const meta = document.querySelector(`meta[name="${name}"]`)
  if (meta) {
    ;(callback && callback(meta)) || meta.parentNode.removeChild(meta)
  }
}

/**
 *
 * @param {Object} data , 就是接口对象
 * @description : 获取套餐接口数据，格式化有效数据
 * @returns Object =>  {
 *        version：: 当前版本
 *        expireTime：有效时间
 *        nextVersion：下一个版本
 * }
 */
export const formatUserPackageInfo = (data) => {
  // 套餐类型
  const versiobnMap = ['free', 'advance_version', 'enterprise_version', 'enterprise_ultimate_version']
  let [version, expireTime, isTry] = ['free', '', false]
  if (data.package_status) {
    /**
     * advance_version 高级版本
     * enterprise_version 企业版标准版
     * enterprise_ultimate_version'; // 企业旗舰版
     */
    isTry = !!data.package_status.is_trial
    version = data.package_status.version
    expireTime = data.package_status.expire_time || ''
  }
  // 免费版本能升级,其他版本只能续费
  const index = versiobnMap.indexOf(version) // 当前版本索引
  const nextVersion = versiobnMap[index === versiobnMap.length - 1 ? versiobnMap.length - 1 : index + 1] // 下一本版本名称
  return {
    version, // 当前版本
    expireTime, // 有效时间
    nextVersion, // 下一个升级版本  名称
    isTry, // 是不是试用版
    isFree: version == 'free', // 是不是免费版本
    isAdv: version === 'advance_version' && !isTry, // 是不是高级版
    isEnt: version === 'enterprise_version' && !isTry, // 是不是企业版
    isUlt: version === 'enterprise_ultimate_version' && !isTry, // 是不是旗舰版
    freeName: 'free',
    advName: 'advance_version', // 高级版名称
    entName: 'enterprise_version',
    ultName: 'enterprise_ultimate_version',
  }
}

// 计算元素相对位置
export function getElemPos(obj) {
  if (!obj) {
    return
  }
  const pos = { top: 0, left: 0 }
  if (obj.offsetParent) {
    while (obj.offsetParent) {
      pos.top += obj.offsetTop
      pos.left += obj.offsetLeft
      obj = obj.offsetParent
    }
  } else if (obj.x) {
    pos.left += obj.x
  } else if (obj.x) {
    pos.top += obj.y
  }
  return { x: pos.left, y: pos.top }
}

/**
 *
 * @param {*instance : 对象实例，params，接口参数,thunkTask: 高阶函数} param0
 * @ 必须是一个同步化函数
 */
export const upDatePackageInfo = async function ({ instance, params = {} } = {}) {
  try {
    const res = await httpMemberPackageInfo(params)
    // 更新套餐
    instance.$store.commit('member/setPackageInfo', res.data)
    return res
  } catch (error) {
    return instance.$store.commit('member/setPackageInfo', error)
  }
}

// 判断是否是小程序环境
export function isWxMiniApp() {
  try {
    const ua = window?.navigator?.userAgent?.toLowerCase()
    const uaIsWxMiniApp = ua.includes('micromessenger') && ua.includes('miniprogram')
    if (uaIsWxMiniApp) return true

    const appname = getQueryString('appname')
    if (appname === 'wj-miniapp') return true

    if (window.__wxjs_environment === 'miniprogram') return true
  } catch (error) {}
}

// 去掉所有html标签
export function deleteHtmlTag(value) {
  const re = /<[^>]*>/g // 去除所有标签
  value = value.replace(re, '')
  return value
}

// 退出登录
export function layout(afterAction) {
  sessionStorage.removeItem('folderId')
  sessionStorage.removeItem('list_pagenum')
  afterAction && (widnow.location.href = '/logout')
}

/**
 *
 * @param {Function} fn  return Array<{name,type,url,options}>
 * @param {Object} methods
 */
export function createHttp(fn, { get, post }) {
  const taskMap = fn() // []
  const apilist = taskMap.reduce((p, n) => {
    const [name, type, url, options] = n
    const methods = ['get', 'g'].includes(type) ? get : post
    p[name] = (params) => {
      return methods(url, params, options)
    }
    return p
  }, {})
  return apilist
}

/**
 * 路由替换
 */

export const useChangeRouter = () => {
  const formatUrl = ({ path = '', query = {} } = {}) =>
    `${path}${path.includes('?') ? '&' : Object.keys(query).length ? '?' : ''}${Object.keys(query).reduce(
      (p, n, i) => `${p}${i ? '&' : ''}${n}=${query[n]}`,
      ''
    )}`
  const setRoute = (params) => Promise.resolve(history.replaceState(null, null, formatUrl(params)))
  try {
    const { useRouter } = Vue
    const Router = useRouter()
    return (params) => {
      const {
        currentRoute: { path: currentRoutePath = '/' },
      } = Router
      const { path } = params
      return path === currentRoutePath ? Router.replace(params).catch((e) => {}) : setRoute(params)
    }
  } catch (e) {
    return setRoute
  }
}

// js中动态加载script，并在load后触发cb
export function loadJS(url, callback) {
  const script = document.createElement('script')
  const fn = callback || function () {}
  script.type = 'text/javascript'
  // IE
  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState == 'loaded' || script.readyState == 'complete') {
        script.onreadystatechange = null
        fn()
      }
    }
  } else {
    // 其他浏览器
    script.onload = function () {
      fn()
    }
  }
  script.src = url
  document.getElementsByTagName('head')[0].appendChild(script)
}
