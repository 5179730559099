// 存放axios实例api请求相关的常量

/**
 * 旧版：主站接口和大部分编辑接口
 * 请求参数签名需要用到的参数
 */
export const SIGNATURECONF_OLD = {
  APP_KEY: 'sqjDmXVd5LNYf9r4',
  SECRET: 'rltfin41xhvwjgyd75s8aq2oebm0369u',
  SITE: 'wenjuan_web',
}

export const SIGNATURECONF_PWA = {
  APP_KEY: 'sqjDmXVd5LNYf9r4',
  SECRET: 'rltfin41xhvwjgyd75s8aq2oebm0369u',
  SITE: 'pwa',
}

/**
 * 新版：部分编辑接口，后续可能都会迁移到新版
 * 请求参数签名需要用到的参数
 */
export const SIGNATURECONF = {
  APP_KEY: 'ZXfC4ZZ',
  SECRET: 'wdH4zeNWo9ZXfC4ZZ0WrNxUM24qWy',
}
