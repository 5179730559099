export const state = () => ({
  // 个人中心-用户基本信息
  basicInfo: {},
  // 企业认证状态
  authInfo: {},
  // 现有套餐内容
  packageInfo: {},
  // 是否展示注销入口
  resetAble: false,
  // 套餐权益余额
  memberBalance: {},
  // 判断是否非为企业空间且非企业主
  memberType: false,
  // 上传空间数据
  uploadSpaceInfo: {},
  // 是不是样本服务的特殊账号
  isSpecialMember: undefined,
})

export const getters = {}

export const actions = {}

export const mutations = {
  // 个人中心-用户基本信息
  setBasicInfo($state, data) {
    $state.basicInfo = data
  },
  setAuthInfo($state, data) {
    $state.authInfo = data
  },
  /**
   *
   * @params{object<{free_package_status?:object}>}
   */
  setPackageInfo($state, data) {
    data.isFreeTry = data?.package_status?.is_trial // 扩展一个新字段作为套餐权益是正在试用中
    data.isWAUser = data?.third_part_type // 微应用
    data.version = data?.package_status?.version || 'free'
    $state.packageInfo = data
  },
  setReset($state, data) {
    $state.resetAble = data
  },
  setMemberBalance($state, data) {
    $state.memberBalance = data
  },
  setMemberType($state, data) {
    $state.memberType = data
  },
  setUploadSpace($state, data) {
    $state.uploadSpaceInfo = data
  },
  setIsSpecialMember($state, data) {
    $state.isSpecialMember = data
  },
}
