// 处理和打包构建的环境参数等相关的模块

/**
 * 根据请求接口的base地址，来判断当前构建环境
 * 项目构建的环境
 *  test：测试、uat：wxtest01、pre：预发布、prod：正式
 *
 * @returns {[string, 'test'|'uat'|'pre'|'prod']} 第一项是具体环境的名称（如：t1），第二项是类别（如：uat）
 */
export function currentEnv() {
  const requestURL = process.env.NUXT_BASE_URL
  const envMap = [
    { match: /http(s)?:\/\/t\d+.wenjuan\.com/i, env: 'test' },
    { match: /http(s)?:\/\/wxtest\d+\.wenjuan\.com/i, env: 'uat' },
    { match: /http(s)?:\/\/www-pre\.wenjuan\.com/i, env: 'pre' },
    { match: /http(s)?:\/\/www\.wenjuan\.com/i, env: 'prod' },
  ]

  for (const v of envMap) {
    if (v.match.test(requestURL)) {
      const { hostname } = new URL(requestURL)
      const subDomain = hostname.split('.')[0]
      return [subDomain, v.env]
    }
  }
  return ['', 'test']
}

/**
 * 从env文件（系统环境变量）注册的process.env对象里取出以NUXT_开头的环境参数
 * env文件里的系统环境变量可以一直在server端使用
 * 而web端代码想使用，只能通过webpack的definePlugin全局替换
 *
 * 注：默认只会取环境变量内以NUXT_前缀的环境变量，如果传入extra，则extra注入的对象也会注入给web，不论是否以NUXT_开头
 *
 * @param {Record<string, string>} extra
 * @returns {Record<string, string>}
 */
export function registerClientEnv(extra = {}) {
  const allEnv = process.env
  const nuxtCustomEnv = {}
  const INJECT_WEB_PREFIX = /^NUXT_/
  for (const key of Object.keys(allEnv)) {
    if (INJECT_WEB_PREFIX.test(key)) {
      nuxtCustomEnv[key] = allEnv[key]
    }
  }
  return { ...nuxtCustomEnv, ...extra }
}

/**
 * nuxt构建的目标enum
 */
export const NUXT_TARGETS = {
  dev: 0,
  build: 1,
  generate: 2,
  start: 3,
}

/**
 * 获取当前nuxt构建的目标
 * @returns {NUXT_TARGETS}
 */
export function currentTargetMode() {
  const subCommand = process.argv[2]
  return NUXT_TARGETS[subCommand]
}

/**
 * 判断nuxt构建目标是否是staic
 *
 * eg：nuxt generate
 * eg：nuxt dev --static
 * eg：nuxt start --static
 * @returns {boolean}
 */
export function isStaticTarget() {
  const subCommand = process.argv[2]
  const flags = process.argv.slice(2)
  const flagStaticRule = /--static/
  const hasFlagStatic = flags.some((v) => flagStaticRule.test(v))
  return subCommand === 'generate' || hasFlagStatic
}
