import { isWxMiniApp } from '~/utils/tools.js'
window.ysf =
  window.ysf ||
  function () {
    ;(window.ysf.a = window.ysf.a || []).push([window, document, 'ysf'])
  }
const temp = document.createElement('script')
temp.async = true
temp.src = 'https://qiyukf.com/script/24ada582654049dffe310a772b0c0db1.js?hidden=1'
if (!isWxMiniApp()) {
  // 非小程序才加载
  document.body.appendChild(temp)
}
