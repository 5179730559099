/* eslint-disable */
import Vue from 'vue'
import * as qiniu from 'qiniu-js'
import { Loading, Message } from '@wenjuan/ui'
import { httpGetQiniuToken, httpGetUploadQiniuToken } from '~/api/common/index.js'
export const EventBus = new Vue()
const qiniuStaticUrl = 'https://static.wenjuan.pub/'

// 前端传图片到七牛
export function uploadImgToQiniu(config) {
  const file = config.file || null // 要上传的文件
  const typeReg = config.typeReg || /(jpg|png|jpeg|gif|bmp|tiff)$/ // 文件类型验证
  const typeErrorHandler = config.typeErrorHandler || null // 文件类型验证不通过触发
  const maxSize = config.maxSize || 5 // 单位M
  const sizeErrorHandler = config.sizeErrorHandler || null // 文件大小验证不通过触发
  const uploadingHandler = config.uploadingHandler || null // 文件开始上传触发
  const errorHandler = config.errorHandler || null // 上传失败触发
  const successHandler = config.successHandler || null // 上传成功触发
  const skipReg = config.skipReg || null // 跳过文件类型验证
  const progressHandler = config.progressHandler || null
  let loadingInstance = null

  if (!file) {
    Message({
      message: '请选择要上传的文件',
      type: 'error',
    })
    return
  }

  const { type } = file
  if (!skipReg && !typeReg.test(type)) {
    if (typeErrorHandler) {
      typeErrorHandler()
    } else {
      Message({
        message: '该图片格式有误, 仅允许JPG, PNG, JPEG, GIF, BMP, TIFF的格式文件',
        type: 'error',
      })
    }
    return
  }
  if (file.size > maxSize * 1024 * 1024) {
    if (sizeErrorHandler) {
      sizeErrorHandler()
    } else {
      Message({
        message: `该图片太大,单张不能超过${maxSize}M`,
        type: 'error',
      })
    }
    return
  }
  if (uploadingHandler) {
    uploadingHandler()
  } else {
    loadingInstance = Loading.service({
      fullscreen: true,
    })
  }
  // 获取上传权限
  httpGetQiniuToken()
    .then((responst) => {
      const ret = responst.originData
      if (ret.status_code === 0) {
        loadingInstance && loadingInstance.close()
        if (errorHandler) {
          errorHandler(ret.err_msg)
        }
        return
      }
      const token = ret.uptoken
      const key = `${ret.key}.${getFileSuffixName(file.name)}`
      const observable = qiniu.upload(file, key, token, {}, {})
      observable.subscribe({
        next(res) {
          if (progressHandler) {
            progressHandler(res)
          }
        },
        error(err) {
          loadingInstance && loadingInstance.close()
          if (errorHandler) {
            errorHandler(err)
          } else {
            Message({
              message: `图片${file.name}上传失败`,
              type: 'error',
            })
          }
        },
        complete(res) {
          // 上传成功 传入图片线上路径
          loadingInstance && loadingInstance.close()
          if (successHandler) {
            successHandler(key)
          }
        },
      })
    })
    .catch((err) => {
      loadingInstance && loadingInstance.close()
      if (errorHandler) {
        errorHandler(err)
      }
    })
}

// 前端传图片到七牛 - 成功直接返回url
export function uploadImgToQiniuV2(config) {
  const file = config.file || null // 要上传的文件
  const typeReg = config.typeReg || /(jpg|png|jpeg|gif|bmp|tiff)$/ // 文件类型验证
  const typeErrorHandler = config.typeErrorHandler || null // 文件类型验证不通过触发
  const maxSize = config.maxSize || 5 // 单位M
  const sizeErrorHandler = config.sizeErrorHandler || null // 文件大小验证不通过触发
  const uploadingHandler = config.uploadingHandler || null // 文件开始上传触发
  const cancelUpload = config.cancelUpload || null // 文件取消上传触发
  const errorHandler = config.errorHandler || null // 上传失败触发
  const successHandler = config.successHandler || null // 上传成功触发
  const skipReg = config.skipReg || null // 跳过文件类型验证
  const progressHandler = config.progressHandler || null
  let loadingInstance = null
  if (!file) {
    Message({
      message: '请选择要上传的文件',
      type: 'error',
    })
    return
  }
  const { type } = file
  if (!skipReg && !typeReg.test(type) && type) {
    if (typeErrorHandler) {
      typeErrorHandler()
    } else {
      Message({
        message: '该图片格式有误, 仅允许JPG, PNG, JPEG, GIF, BMP, TIFF的格式文件',
        type: 'error',
      })
    }
    return
  }
  if (file.size > maxSize * 1024 * 1024) {
    if (sizeErrorHandler) {
      sizeErrorHandler()
    } else {
      Message({
        message: `该图片太大,单张不能超过${maxSize}M`,
        type: 'error',
      })
    }
    return
  }
  if (uploadingHandler) {
    uploadingHandler()
  } else {
    loadingInstance = Loading.service({
      fullscreen: true,
    })
  }
  const { projectId, questionId, keyType } = config
  const param = {
    pid: projectId,
    type: keyType,
    file_name: file.name,
  }
  if (questionId) {
    param.question_id = questionId
  }
  // 获取上传权限
  httpGetUploadQiniuToken(param)
    .then((response) => {
      const ret = response.originData
      if (ret.status_code === 0) {
        loadingInstance && loadingInstance.close()
        if (errorHandler) {
          errorHandler(ret.err_msg)
        }
        return
      }
      const { key, token } = ret.data
      const observable = qiniu.upload(file, key, token, {}, {})

      const tmpObservable = observable.subscribe({
        next(res) {
          if (progressHandler) {
            progressHandler(res)
          }
        },
        error(err) {
          loadingInstance && loadingInstance.close()
          if (errorHandler) {
            errorHandler(err)
          } else {
            Message({
              message: `图片${file.name}上传失败`,
              type: 'error',
            })
          }
        },
        complete(res) {
          // 上传成功 传入图片线上路径
          loadingInstance && loadingInstance.close()
          if (successHandler) {
            successHandler(qiniuStaticUrl + key)
          }
        },
      })

      // 监听取消上传
      EventBus.$on('appearanceCancelUpload', () => {
        loadingInstance && loadingInstance.close()
        tmpObservable.unsubscribe()
        EventBus.$off('appearanceCancelUpload')
        if (cancelUpload) {
          cancelUpload()
        }
      })
    })
    .catch((err) => {
      loadingInstance && loadingInstance.close()
      if (errorHandler) {
        errorHandler(err)
      }
    })
}

// 获取图片宽高
function getImageSize(imageUrl) {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = imageUrl
    image.onload = function () {
      resolve({
        width: image.width,
        height: image.height,
      })
    }
    image.onerror = function () {
      reject()
    }
  })
}

// 压缩/缩略图
export function compressImage(imageUrl) {
  return getImageSize(imageUrl)
    .then(({ width, height }) => {
      const originWidth = width
      // 原图压缩
      const compressRefWidth = 800
      let newUrl = imageUrl
      if (width > compressRefWidth) {
        const ratio = compressRefWidth / width
        width = parseInt(width * ratio)
        height = parseInt(height * ratio)
        newUrl = `${imageUrl}?${encodeURIComponent(`imageMogr2/auto-orient/thumbnail/${width}x${height}>`)}`
      }

      // 缩略图
      const thumbnailRefWidth = 300
      let thunmbnailUrl = imageUrl
      let minWidth = width > height ? height : width
      if (minWidth > 300) {
        minWidth = thumbnailRefWidth
      }
      thunmbnailUrl = `${imageUrl}?imageView2/1/w/${minWidth}/h/${minWidth}`
      return {
        width,
        originWidth,
        src: newUrl,
        thunmbnailUrl,
      }
    })
    .catch(
      () =>
        // 出错返回null
        null
    )
}

// 生成裁切后的图片url
export function getCropImageUrl(originUrl, originWidth, bbox) {
  // 裁切弹窗中限制了图片最大宽度为400，当宽度>400时，根据bbox生成原始图片的裁切尺寸
  let x = bbox[0]
  let y = bbox[1]
  let width = bbox[2] - bbox[0]
  let height = bbox[3] - bbox[1]

  // 异常处理
  if (x < 0 || y < 0) {
    return originUrl
  }

  if (originWidth > 400) {
    const ratio = originWidth / 400
    // 起点
    x *= ratio
    y *= ratio
    width *= ratio
    height *= ratio
  }
  if (originUrl.indexOf('imageMogr2') === -1) {
    originUrl += '?imageMogr2'
  }
  originUrl += `/crop/!${width}x${height}a${x}a${y}`

  return originUrl
}

// 上传base64编码图片到七牛云
export function uploadBase64(data, fn) {
  const pic = data.imgSrc.slice(data.imgSrc.indexOf(',') + 1)
  const url = `https://upload.qiniup.com/putb64/-1/key/${data.key}` // 非华东空间需要根据注意事项 1 修改上传域名
  const xhr = new XMLHttpRequest()
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4) {
      // 上传成功后处理
      if (fn) fn(xhr.responseText)
    }
  }
  xhr.open('POST', url, true)
  xhr.setRequestHeader('Content-Type', 'application/octet-stream')
  xhr.setRequestHeader('Authorization', `UpToken ${data.token}`)
  xhr.send(pic)
}

// 获取缩略图url
export function getThunmbnailUrl(imageUrl, question) {
  return getImageSize(imageUrl)
    .then(({ width, height }) => {
      // 缩略图
      const thumbnailRefWidth = 300
      let thunmbnailUrl = imageUrl
      let minWidth = width > height ? height : width
      if (minWidth > 300) {
        minWidth = thumbnailRefWidth
      }
      let minHeight = minWidth
      const { custom_attr: { img_scale: imgScale } = {} } = question
      if (imgScale) {
        // 图片显示比例
        if (imgScale === 'vertical') {
          minWidth = Math.ceil((minWidth * 3) / 4)
        }
        if (imgScale === 'verticalSix') {
          minWidth = Math.ceil((minWidth * 9) / 16)
        }
        const imgScaleData = {
          auto: (minWidth * height) / width,
          default: minHeight,
          transverse: (minWidth * 3) / 4,
          transverseSix: (minWidth * 9) / 16,
          vertical: (minWidth * 4) / 3,
          verticalSix: (minWidth * 16) / 9,
        }
        minHeight = Math.ceil(imgScaleData[imgScale])
      }
      const isVideo = imageUrl.includes('?vframe/jpg/offset/1')
      thunmbnailUrl = `${imageUrl}?imageView2/1/w/${minWidth}/h/${minHeight}`
      if (isVideo) {
        thunmbnailUrl = `${imageUrl}|imageView2/1/w/${minWidth}/h/${minHeight}`
      }

      return {
        width,
        src: imageUrl,
        thunmbnailUrl,
      }
    })
    .catch(() => imageUrl)
}

// 获取文件后缀名
export function getFileSuffixName(fileName) {
  const fileNameArray = `.${fileName}`.split('.')
  const filesuffixName = fileNameArray[fileNameArray.length - 1]
  return filesuffixName
}
