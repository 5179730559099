import { set as setStorage, remove as removeStorage } from "~/utils/storage.js"

export default ({ app }, inject) => {
  window.addEventListener('load', function() {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register('/pwa/sw.js');
    }
  });

  window.addEventListener('beforeinstallprompt', (e) => {
    // 阻止浏览器默认的安装提示
    e.preventDefault();
    // 将事件保存下来，以便稍后使用
    app.$pwaInstallPromptEvent = e;
    window.$pwaInstallPromptEvent = e;
    // localStorage 标记已经执行过 beforeinstallprompt 事件，下次进入页面根据这个字段显示或隐藏列表页的 pwa 安装引导弹窗
    setStorage('pwa_beforeinstallprompt', true);
  });


  window.addEventListener('appinstalled', () => {
    // App was installed
    console.log('PWA was installed');
    app.$pwaIsInstall = true;
    window.$pwaIsInstall = true;
    window.zytrack && window.zytrack('custom', 'Pwa_AddSuccessfully')
    // 安装 pwa 后，移除 pwa_beforeinstallprompt 标记
    removeStorage('pwa_beforeinstallprompt');
  });
};