// VConsole，用于移动端浏览器调试
// 仅在测试版本使用，上线后必须删除!
// 目前通过NUXT_VCONSOLE来手动开启
if(process.env.NUXT_VCONSOLE === '1') {
  (function () {
    const t = document.createElement('script')
    t .async = 1
    t .src = '//unpkg.com/vconsole@latest/dist/vconsole.min.js'
    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(t , s)
  })()
  
  window.addEventListener('load', () => {
    if (window.VConsole) {
      new window.VConsole()
    }
  })
}