
import { mapState } from 'vuex'
import { getUserInfo } from '~/api/common.js'
import { compose } from '~/utils/tools.js'
import headerLightConf from '~/utils/header-highlight/index.js'
import registerConfig from '~/utils/registerConfig/index.js'
import $WATCH from '~/api/watch-pipe.js'
export default {
  name: 'NewHeaderOut',
  data() {
    const time = new Date().getTime()
    const endTime = Date.parse('2025-02-13')
    return {
      isNewYear: time < endTime,
    }
  },
  computed: {
    ...mapState(['mediaType']),
    isLogin() {
      const storeLoggedIn = this.$store.state.loginStatus.loggedIn
      return storeLoggedIn == 1
    },
    attrs() {
      return { ...this?.$attrs }
    },
    logoImage() {
      return ''
    },
  },

  mounted() {
    //  处理一些特殊页面不缓存或者获取不准确的时候，未登入下重新获取一次cookie的数据
    if (!this.isLogin) {
      this.updateLoginStatue()
    }
  },
  methods: {
    // 导航点击
    doClick(type = 'toList') {
      let params = this.$store.state.loginStatus.registerIndexParams
      const routePath = { next: encodeURIComponent(window.location.href) }
      const aim = registerConfig.find((v) => this.$route.path.startsWith(v.path))
      if (aim) {
        // 默认 to 和dialog 不存在的话就是回到 当前页面+参数
        params = typeof aim.to === 'function' ? aim.to(this) : aim.to || routePath
        const conf = {
          config: {
            nexturl: typeof aim.dialogTo === 'function' ? aim.dialogTo(this) : aim.dialogTo || window.location.href,
          },
        }
        this.$store.commit('setLoginStatus', conf)
      }
      // 获取URL 上需要拼接的参数
      ;({
        login: () => this.$href('/login/', params, false),
        use: () => {
          this.$store.commit('setLoginStatus', { show: true })
        },
        toList: Function(),
      })[type]()
    },
    // 更新用户登录信息
    updateLoginStatue() {
      getUserInfo()
        .then((res) => {
          this.$store.commit('setLoginStatus', { loggedIn: 1 })
          this.$store.commit('setUserInfo', { _init: true, ...res.data })
          $WATCH.emit('headerEndFetchUserInfo')
        })
        .catch((e) => {
          console.log('获取用户信息接口报错')
          this.$store.commit('setLoginStatus', { loggedIn: 0 })
        })
    },
    // thunk函数 +  柯里化函数
    setConf(data) {
      const object = JSON.parse(JSON.stringify(data))
      const { list } = object
      const { 'set-conf': setConfTask, setConf } = this.$attrs || {}
      const __setConf__ = setConfTask || setConf
      const flarMap = (mapList, listTotal = []) => {
        mapList.forEach((element) => {
          if (Array.isArray(element)) {
            flarMap(element, listTotal)
          } else {
            listTotal.push(element)
          }
        })
        return listTotal
      }
      const processThunk =
        (key) =>
        ({ key: subActive }) => ({
          active: key,
          subActive,
        })
      const processCurry =
        (key) =>
        ({ menu }) =>
          menu.map(processThunk(key))
      const processStart = ({ key, subMenu }) => (subMenu ? subMenu.map(processCurry(key)) : { key })
      const startMap = (list) => list.map(processStart)
      const mapList = compose(flarMap, startMap)(list)
      // 业务逻辑处理
      const path = this.$route.path
      const __path__ = mapList.find(
        // 这里由于prettier 的格式化配置有问题 暂时没去管理 ，强制转换some 为includes 所以使用 多 ||  符号判断
        ({ subActive, active }) =>
          `/${subActive}` === path || `/${subActive}/` === path || `/${active}/` === path || `/${active}` === path
      )
      //  禁用多次commit 导致vue 组件反复渲染
      const { active, subActive } = this.$store.state.headerActive
      if (__path__) {
        // 基础匹配规则
        if (active !== __path__.active && subActive !== __path__.subActive)
          this.$store.commit('setHightLightHeader', __path__)
      } else {
        // 混合匹配规则
        const mixin = headerLightConf.find((item) => path.includes(item.path))
        if (mixin && active !== mixin.active && subActive !== mixin.subActive)
          this.$store.commit('setHightLightHeader', mixin)
      }
      // 组件必须用 return, 业务类型可以不用 return
      return __setConf__ && __setConf__(object) // Function <Object => Array | undefind | null>
    },
  },
}
