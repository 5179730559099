/**
 * @description 监听管道 监听数据是否结束，结束则调取处理新事务
 */
export default (function () {
  const targetMap = {}
  const pipe = {}
  const taskMap = new Map([['default', () => `this keyName is not Function ,select your task key`]])
  const evts = {}
  let timer = null
  return Object.seal({
    add(key) {
      pipe[this.toggle(key)] = pipe[this.toggle(key)] === undefined ? 1 : pipe[this.toggle(key)] + 1
      clearTimeout(timer)
      return this
    },
    sub(key, target) {
      const ket = this.toggle(key)
      const list = targetMap[ket]
      list ? list.push(target) : (targetMap[ket] = [target])
      if (!pipe[ket]) return this
      pipe[ket]--
      if (pipe[ket] === 0 && target !== this.end.endTarget)
        taskMap.get(ket) && (timer = setTimeout(this.done.bind(this, ket, 500)))
    },
    end(key, fn, endTarget) {
      this.end.endTarget = endTarget
      if (!key) return
      const _key = this.toggle(key)
      this.start(_key)
      taskMap.set(this.toggle(key), fn)
    },
    remove(key) {
      taskMap.delete(this.toggle(key))
      return this
    },
    done(key) {
      const ket = this.toggle(key)
      const task = taskMap.get(ket)
      try {
        if (task) task()
      } catch (e) {
        console.error(e, 'taskMap is error')
        taskMap.get('default')?.()
      }
    },
    toggle(key) {
      return key.toLowerCase()
    },
    start(key) {
      pipe[key] = 0
    },
    get(key) {
      if (Object.hasOwn(this, this.toggle(key))) return this[this.toggle(key)]
    },
    emit(evt, ...data) {
      const _evt_ = evts[evt]
      if (!_evt_) return false
      _evt_.forEach(function (task) {
        task(...data)
      })
    },
    on(evt, task) {
      const _evt_ = evts[evt]
      if (_evt_) {
        !_evt_.includes(evt) && _evt_.push(task)
      } else {
        evts[evt] = [task]
      }
    },
    off(evt, task) {
      const _evt_ = evts[evt]
      if (_evt_ && task) {
        _evt_.splice(_evt_.indexOf(task), 1)
      } else if (_evt_) {
        Reflect.deleteProperty(evts, evt)
      }
    },
  })
})()
