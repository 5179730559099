import api from './base.js'

// 获取用户现有套餐版本
export function httpMemberPackageInfo(params) {
  return api.get('/plugin/api/v1/member_package/info/', { params })
}

// 获取当前空间
export function fetchCurrentSpace() {
  return api.get('/api/enterprise/current_space/')
}

// 获取套餐列表
export function httpPackageList() {
  return api.get('/plugin/api/v1/package/info/')
}
/**
 * 获取用户信息
 * @returns
 */
export function getUserInfo(params) {
  return api.get('/member/api/info/simple/', { params })
}

// 发送付费套餐线索
export function httpSendPayClue(data) {
  return api.post('/api/cms_pay_clue/pay_clue/create/', data)
}

/**
 * 获取充值订单id
 * @param { object } data
 * @param { string } data.money 充值金额
 * @param { string } data.pay_channel 支付类型
 * @param { string } data._xsrf
 * @returns
 */
export function getPayOrderId(data) {
  return api.post('/member/payorder/', data)
}

/**
 * 检查充值状态
 * @param { object } data
 * @param { string } data.order_id 充值订单id
 * @param { string } data._xsrf
 * @returns
 */
export function getPayStatus(data) {
  return api.post('/member/validatepayorder/', data)
}

/**
 * 提现
 * @param { object } data
 * @param { string } data.withdraw_type
 * @param { string } data.action
 * @param { string } data.v_code
 * @param {{account_hodler: string, account_number: string, amount: string, mobile: string, bank_name: string }} data.withdraw_data
 * @param { string } data._xsrf
 * @returns
 */
export function doWithdrawal(data) {
  return api.post('/auth/save_withdraw_data/', data)
}

// 修改用户基础信息
export function updateMemberInfo(data) {
  return api.post('/member/update_member_info/', data)
}

// 绑定销售人员
export function bindingSaler(data) {
  return api.post('/api/member/channel-code/set/', data)
}
