import Vue from 'vue'
import api from '~/api/base'

// 因为business组件存在问题，它在js一导入，就去获取Vue.prototype.commonRequest函数，你不导入就等着报错把！
// 提供给business内部组件使用
// 必须按照code仓库的请求函数格式，它不是标准的axios函数签名！！！！！！！！！！！
// https://code.idiaoyan.cn/wj/fe/wenjuan-business
Vue.prototype.commonRequest = {
  get(url, pas, opts) {
    const { unUseTransition = true, useErrorTip = true, ...params } = pas || {}
    return api.get(url, { params, unUseTransition, useErrorTip, ...opts })
  },
  post(url, data, headers) {
    const { unUseTransition = true, useErrorTip = true, ...surplus } = data || {}
    return api.post(url, data, { headers, unUseTransition, useErrorTip, ...surplus }).catch((res) => {
      return Promise.reject(res)
    })
  },
  delete(url, data) {
    return api.delete(url, { data, useErrorTip: true })
  },
  put(url, data) {
    return api.put(url, data, { useErrorTip: true })
  },
  patch(url, data) {
    return api.patch(url, data)
  },
  download(url, params) {
    return api.get(url, { params, responseType: 'blob', useErrorTip: true })
  },
}
