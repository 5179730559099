/**
 * @wenjuan/business纯客户端插件
 * 必须注意该包内某些组件是不兼容SSR的，这些组件使用时需要注意：
 * 1、使用组件时，必须放到client-only组件内部，否则SSR渲染时报错
 */
import Vue from 'vue'
import { TemplatePreview, CommonInnerHeader, VotePlanCarousel } from '@wenjuan/business'

Vue.use(TemplatePreview)
Vue.use(CommonInnerHeader)
Vue.use(VotePlanCarousel)
