/**
 * @wenjuan/ui2已处理成支持按需引入，不能全局引入
 *
 * https://wj-toolkit.idiaoyan.cn/wenjuan/ui2#/zh-CN/component/quickstart
 */
import Vue from 'vue'
import {
  Button,
  Tooltip,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  Card,
  Loading,
  Message,
  MessageBox,
  Carousel,
  CarouselItem,
  Pagination,
  Dialog,
  Tabs,
  TabPane,
  Image,
  Select,
  Cascader,
  Option,
  Scrollbar,
  Badge,
  Table,
  TableColumn,
  Menu,
  Submenu,
  MenuItem,
  Form,
  FormItem,
  Upload,
  Autocomplete,
  Switch,
  CheckboxButton,
  CheckboxGroup,
  Popover,
  Drawer,
  DatePicker,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Progress,
  Steps,
  Step,
  Tree,
} from '@wenjuan/ui2'
Vue.prototype.$loading2 = Loading.service
Vue.prototype.$message2 = Message
Vue.prototype.$confirm2 = MessageBox.confirm
Vue.prototype.$msgbox2 = MessageBox
Vue.prototype.$alert2 = MessageBox.alert
// Vue.prototype.$loading = Loading.service
// Vue.prototype.$message = Message
// Vue.prototype.$confirm = MessageBox.confirm
// Vue.prototype.$msgbox = MessageBox
// Vue.use(Loading.directive)
Vue.use(Button)
Vue.use(Tooltip)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Checkbox)
Vue.use(Card)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Image)
Vue.use(Select)
Vue.use(Cascader)
Vue.use(Option)
Vue.use(Scrollbar)
Vue.use(Badge)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Upload)
Vue.use(Autocomplete)
Vue.use(Switch)
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)
Vue.use(Popover)
Vue.use(Drawer)
Vue.use(DatePicker)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Progress)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Tree)
