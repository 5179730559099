
import { createObserver } from '~/utils/tools.js'

export default {
  description: '列表页滚动组件',
  name: 'CommonListscrollbar',
  props: {
    height: {
      type: String,
      default: '',
    },
    // 是否开启懒加载
    openLazy: {
      type: Boolean,
      default: true,
    },
    // 懒加载函数, promise| asyns 函数
    /**
     * async | promise 函数， 懒加载一定存在这个函数 是判断懒加载初始化的依据
     *  1：async 返回值返回false 则停止懒加载监听，
     * 2: 解决特殊的逻辑 有其他返回值做参考值的返回值 callback 的返回值 等同 lazying  1方案的返回值 效果
     */
    lazying: {
      type: Function, // （object<{intersection,intersectionObserver,callback}>） => boolean
      default: null,
    },
    // 加载速度
    loadSpeed: {
      type: Number,
      default: 50,
    },
    useCustomStyle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      useCallback: null,
      showTag: true, // 内容操作
    }
  },
  watch: {
    openLazy: {
      handler(cval) {
        this.showTag = cval
      },
      immediate: true,
    },
  },
  mounted() {
    this.lazying && this.init()
  },
  beforeDestroy() {
    this.init.intersector && this.init.intersector.close()
  },
  methods: {
    init() {
      const dom = this.$refs.intersection
      if (!dom) return
      // thunk函数拦截中间件
      // useCallback 可以是函数  可以是 boolean ，是函数返回值是一个boolean
      // 意义 ：为假 则关闭懒加载监听，请求到重点的时候需要关闭懒加载
      this.init.intersector = createObserver()
        .add(dom)
        .then(async (isIntersecting, intersectionObserver) => {
          if (!this.showTag || !this.openLazy || !this.lazying) return
          this.showTag = false
          let resfine
          if (isIntersecting) {
            resfine = await this.lazying?.({ isIntersecting, intersectionObserver })
          }
          setTimeout(() => {
            if (this.openLazy) this.showTag = typeof resfine === 'boolean' ? resfine : true
          }, this.loadSpeed)
        })
    },
  },
}
