// 首页重定向的相关逻辑中间件
export default function ({ store, route, req, res, redirect, query }) {
  // 需要兼容static构建
  if (process.server && !process.static) {
    const { headers } = req
    const { formated } = store.state.cookie
    const { isWeixin, isWxwork } = store.state.mediaType
    const { loggedIn } = store.state.loginStatus
    const loginUrl = route.query.login_furl
    const { isMobile } = store.state

    if (loggedIn && isMobile) {
      redirect('/m/?from=pc_home_')
    } else if (!isMobile && loggedIn === 1 && !headers.referer?.includes('wenjuan.com')) {
      // 外部网站进入该页面，重定向到list
      if (Object.keys(query).length) return
      redirect({
        path: '/list/',
        query: {
          from: 'home',
          middleware: true,
        },
      })
    }

    // 微信公众号第一次直接使用微信登录(account_a)
    // 退出登录后使用其他方式登录(account_b)
    // 重定向登录页
    if (isWeixin && !isWxwork && !formated.account_b && loginUrl) {
      let expiresTime = 30 * 24 * 60 * 60 * 1000 + new Date().getTime()
      expiresTime = new Date(expiresTime).toUTCString()
      res.setHeader('Set-Cookie', `gzh_login=1; expires=${expiresTime}`)
      redirect(loginUrl)
    }

    // 清除登录后的login_furl cookie
    if (formated.login_furl) {
      const nowTime = new Date().toUTCString()
      // 浏览器在接收当前过期的cookie后，会自动去除该字段
      res.setHeader('Set-Cookie', `login_furl=''; expires=${nowTime}`)
    }
  }
}
