/* eslint-disable no-void */
export const state = () => ({
  loading: false, // 全页面loading
  clientScrollWidth: 17, // scrollWidth
  clientWidth: 0, // 可视化DOM的宽度、
  innerWidth: 0, // 可见最大宽度区
  isHandset: false, // 是移动手机 且不是ipad【 本次迭代 主要以这个为主】
  isMobile: false, // 只要是移动设备
  isPad: false,
  isWAUser: false, // 是不是微应用用户
  // 读取UA的各种判断
  mediaType: {
    userAgent: '', // UA头原始字符串，兼容server和client获取,
    isHandset: '',
    isMobile: '',
    clientScrollWidth: '',
    isPad: '',
  },
  // 登入弹窗的维护配置，基于弹窗组件的代码去维护
  loginStatus: {
    // 登入状态维护对象
    visible: false, // 登入弹窗
    show: false, // 兼容老板使用更多弹窗打开属性
    // beforClose: Function, // 关闭弹窗前做什么操作
    loggedIn: 0, // 是否登入
    initType: 'last-type',
    nextUrl: '/list',
    mobileVisible: false,
    mobileRedirectUrl: '/m/',
    miniAppLogin: true,
  },
  templateCard: {
    show: false,
    qrcode: '',
  },
  /**
   * @param type sceneTypeList = Array<{title, en_title}>
   */
  sceneTypeList: [], // 存储项目类型的标签列表
  headerActive: {
    // 当前导航的高亮菜单
    active: '',
    subActive: '',
    show: true,
    transparent: false,
  },
  footerInfo: {
    // 底部组件状态控制
    show: false,
  },
  userInfo: {
    isWAUser: false,
  }, // 用户信息
  listPageConf: {
    space: true, // 是不是企业空间
    isSubAccount: false, // 是不是子账号
  }, // 全局变量
  usercomboType: '', // 用户套餐类型
  isFreeTry: false, // 是否试用版本
  isReportV2: false,
  // 套餐购买弹窗信息
  packageDialog: {
    show: false,
    activeVersion: 'advance_version',
    lowVersion: 'advance_version',
    source: '',
  },
  testData: {},
  // 样本服务
  sampleDialogInfo: {
    visible: false, // 显示
    personServer: false, // 是不是只显人工服务
    activeService: '', // person-service 人工服务
    source: '', // 来源
    sampleOrderCount: 0, // 收集订单个数
    hasSampleOrder: false, // 是否收集了订单信息
    show: false,
    isSpecialMember: false,
    needReload: false,
  },
})

export const getters = {
  // 当前用户是否处于企业空间
  isEnterpriseSpace(state) {
    return state.listPageConf.space_type === 2
  },
  // 当前用户是否是企业主身份
  isEnterpriseAdmin(state) {
    return state.listPageConf.is_enterprise_admin === 1
  },
}

export const actions = {
  isLoggedAction({ commit }, { loginStatus }) {
    !loginStatus &&
      commit.setLoginStatus({
        show: true,
      })
  },
}

export const mutations = {
  // 全局响应式
  getClientWidth($state, data) {
    data.clientWidth && ($state.clientWidth = data.clientWidth) // 除去滚轴宽度
    data.innerWidth && ($state.innerWidth = data.innerWidth) // 可见最大宽度
  },
  // card-item 的弹窗预览
  setPreViewTempalte($state, data) {
    console.log(data)
    Object.assign($state.templateCard, data)
  },
  // 初始化项目后 创建项目类型列表
  // data ：Array<{name:名称，scene_type: 类型枚举，name_short：名称简称}>
  setSceneTypeList($state, data) {
    $state.sceneTypeList = data
  },
  // 设置登入状态弹窗
  setLoginStatus($state, data) {
    //  状态赋值有问题，如果不传config，会导致config的值被清空
    if (data.show !== undefined) {
      $state.loginStatus.visible = data.show
    }
    Object.assign($state.loginStatus, data)
  },
  // 设置高亮
  setHightLightHeader($store, data) {
    $store.headerActive = {
      ...$store.headerActive,
      ...data,
    }
  },
  // 媒体查询
  setMediaType($state, data) {
    $state.mediaType = { ...$state.mediaType, ...data }
    $state.isHandset = data.isMobile && !data.isPad // 纯手机设备
    $state.isMobile = $state.mediaType.isMobile // 手机端
    $state.clientScrollWidth = $state.mediaType.isMac ? 0 : 17
    $state.isPad = $state.mediaType.isPad
  },
  // 设置底部状态（目前用于隐私9+页，<640隐藏底部组件使用）
  setFooterInfo($state, data) {
    Object.assign($state.footerInfo, data)
  },
  // 设置用户信息
  setUserInfo($state, data) {
    $state.userInfo = { ...$state.userInfo, ...data }
    if ($state.isWAUser === $state.userInfo?.isWAUser) return
    $state.isWAUser = $state.userInfo?.isWAUser
  },

  // 设置列表页面全局变量 比如 空间类型
  // is_enterprise_admin，是不是企业主1(是);  space_type  个人空间/企业空间
  setListPageConf($state, task) {
    const data = {
      ...$state.listPageConf,
      ...task,
    }
    const { is_enterprise_admin: isEnterpriseAdmin, space_type: spaceType } = data
    $state.listPageConf = {
      ...data,
      space: isEnterpriseAdmin === 1 || spaceType === 2, // true = 企业空间 | false =个人空间
      // 主账号 和子账号
      isSubAccount: spaceType === 2 && isEnterpriseAdmin !== 1, //  是不是子账号， 其他都是主账号
    }
  },
  // 设置用户套餐种类
  setUserCombo($state, data) {
    $state.usercomboType = data
  },
  // 记录是否试用
  setUserTry($state, data) {
    $state.isFreeTry = data
  },
  setIsReportV2($state, data) {
    $state.isReportV2 = data
  },
  // 设置登入状态弹窗
  setPackageDialog($state, data) {
    $state.packageDialog = data
  },
  // 测试微前端
  setDevRoles($state, { isWAUser, isFreeTry, ...other }) {
    const data = $state.member.packageInfo
    if (isWAUser !== void 0) data.isWAUser = isWAUser
    if (isFreeTry !== void 0) data.isFreeTry = isFreeTry
    $state.member.packageInfo = { ...data }
    if (other) Object.assign($state.mediaType, other)
  },
  setTest($state, data) {
    $state.testData = data
  },
  // 设置样本服务弹窗
  setSampleDialog($state, data) {
    $state.sampleDialogInfo = {
      ...$state.sampleDialogInfo,
      ...data,
    }
  },
  // 顶层加载
  setLoading($state, data) {
    $state.loading = data
  },
}
