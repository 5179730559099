/**
 * @wenjuan/ui已处理成支持按需引入，不能全局引入
 *
 * https://wj-toolkit.idiaoyan.cn/wenjuan/ui#/zh-CN/component/quickstart
 */
import Vue from 'vue'
import {
  Button,
  Tooltip,
  Input,
  Card,
  Loading,
  Message,
  MessageBox,
  Carousel,
  CarouselItem,
  Pagination,
  Dialog,
  Tabs,
  TabPane,
  Image,
  Select,
  Option,
  Checkbox,
  Scrollbar,
  Badge,
  Table,
  TableColumn,
  Tag,
  Menu,
  Submenu,
  MenuItem,
  Popover,
  Progress,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from '@wenjuan/ui'
Vue.prototype.$loading = Loading.service
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$msgbox = MessageBox
Vue.use(Loading.directive)
Vue.use(Button)
Vue.use(Tooltip)
Vue.use(Input)
Vue.use(Card)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Image)
Vue.use(Select)
Vue.use(Option)
Vue.use(Checkbox)
Vue.use(Scrollbar)
Vue.use(Badge)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tag)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Popover)
Vue.use(Progress)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
