import Vue, { getCurrentInstance } from 'vue'
import qs from 'qs'
import { tools } from '@wenjuan/business'
import { mergeObject, compose, removeEmpty } from '~/utils/tools.js'

const { defineObject, useObjects, defineFunction, useHooks, hooks } = tools
Vue.use(hooks, { getCurrentInstance })
defineObject.assign(defineObject, useObjects)
defineObject.assign(defineFunction, useHooks)
Vue.useCommit = (name, data) => getCurrentInstance()?.proxy?.$Store.commit(name, data)
Vue.useOptions = ({ head, ...surplus } = {}) => {
  const $options = getCurrentInstance()?.proxy?.$options
  mergeObject($options, surplus)
  if (head)
    $options.head = function () {
      return {
        ...(typeof $options?.head === 'function' ? $options?.head() : $options?.head),
        ...head,
      }
    }
  return $options
}

const extendsOptions = {
  // type 为真，则不自动转化参数为decodeURIComponent
  $formatUrl(url, query = {}, useURICompoment = true) {
    const format = !useURICompoment ? this.$setUrlOriginParams : this.$setUrlparams
    const urlParams = compose(format, removeEmpty)(query)
    const path = [url, '?', urlParams]
    return path[2] ? path.join('') : url
  },
  /**
   *
   * @param {string} url
   * @param {Object} query
   * @param {Boolean} type  : true 不使用URI转码， false
   */

  $href(url, query = {}, useURICompoment = true) {
    window.location.href = this.$formatUrl(url, query, useURICompoment)
  },
  // 打开新窗口
  $open(url, ...options) {
    window?.open?.(url, ...options)
  },
  $typeof: (type) => Object.prototype.toString.call(type).slice(8, -1).toLowerCase(),
  $scrollWidth: 15, // 滚轴边框，用来做JS 响应式布局的算法
  $getUrlparams: (...params) => qs.parse(...params),
  // 自动uriComponent 转化数据
  $setUrlparams: (...params) => qs.stringify(...params),
  // 使用 原始格式 不用decodeURIcompoment 包裹内容
  $setUrlOriginParams: (object) => {
    return Object.keys(object).reduce((p, n, i) => `${p}${i ? '&' : ''}${n}=${object[n]}`, '')
  },
  $status: ({ status_code, status }) => status_code === 1 && status === 200,
  $preViewTemplate: {
    show: false,
    qrcode: '',
    closeDialog() {
      this.show = false
    },
  },
  $colorPrimary: '#2672ff', // 全局颜色
  $pointWidth: 1330, // 临界点宽度
  $test: true,
  $msg: function (message, options) {
    const { isMobile } = this.$store.state
    if (isMobile) {
      if (options.type === 'error') options.type = 'fail'
      this.$toast({
        message,
        ...options,
      })
    } else {
      this.$message({ message, ...options })
    }
  },
  $cdn: '//s1.wenjuan.com/',
  $cdnhome: '//s1.wenjuan.com/assets/images/homesite/',
  $serviceWorkerIdImage: `https://s1.wenjuan.com/assets/images/customer-service/counselor${Math.ceil(
    Math.random() * 4
  )}.png`,
  fetchPipe: Function.usePipe(({ status, status_code }) => status === 200 && status_code === 1),
}
Object.assign(Vue.prototype, extendsOptions)
