import { render, staticRenderFns } from "./template-page.vue?vue&type=template&id=5180d106&scoped=true&"
import script from "./template-page.vue?vue&type=script&lang=js&"
export * from "./template-page.vue?vue&type=script&lang=js&"
import style0 from "./template-page.vue?vue&type=style&index=0&id=5180d106&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5180d106",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonNewHeaderOut: require('/builds/wj/fe/wenjuan-homesite/components/common/new-header-out.vue').default,CommonDevModeWrap: require('/builds/wj/fe/wenjuan-homesite/components/common/dev-mode-wrap.vue').default})
