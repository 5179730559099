import { render, staticRenderFns } from "./scence-single.vue?vue&type=template&id=205bf3ae&scoped=true&"
import script from "./scence-single.vue?vue&type=script&lang=js&"
export * from "./scence-single.vue?vue&type=script&lang=js&"
import style0 from "./scence-single.vue?vue&type=style&index=0&id=205bf3ae&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "205bf3ae",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonNewHeaderOut: require('/builds/wj/fe/wenjuan-homesite/components/common/new-header-out.vue').default,CommonDevModeWrap: require('/builds/wj/fe/wenjuan-homesite/components/common/dev-mode-wrap.vue').default})
