import { httpMemberPackageInfo } from '~/api/common.js'

// 用户套餐版本的枚举，按照数组index升序
export const MEMBER_PKG_TYPE_ENUM = [
  'free', // 后端无此字段，前端定义
  'advance_version',
  'enterprise_version',
  'enterprise_ultimate_version',
  'enterprise_ultimate_free_version',
]

// 用户套餐版本对应中文
export const MEMBER_PKG_TYPE_MAPPING = {
  free: '基础版',
  advance_version: '高级版',
  enterprise_version: '企业版',
  enterprise_ultimate_version: '旗舰版',
  enterprise_ultimate_free_version: '旗舰版（试用）',
}

/**
 * 包装packageDialog需要的所有参数
 * @param {*} PKGList 所有套餐信息列表
 * @param {*} memberPKG 当前用户套餐信息
 * @param {*} memberPKGType 当前用户套餐版本
 * @param {*} nextPKGType 需要升级的套餐版本，如果省略，则默认取下一个版本
 * @returns
 */
export function dialogInit(PKGList, memberPKG, memberPKGType, nextPKGType) {
  const i = MEMBER_PKG_TYPE_ENUM.findIndex((type) => type === memberPKGType) || 0
  const nextType = nextPKGType || MEMBER_PKG_TYPE_ENUM[i + 1]
  let packageType = ''

  if (nextType === 'enterprise_version') {
    packageType = 'enterprise'
  }
  if (nextType === 'enterprise_ultimate_version') {
    packageType = 'ultimate'
  }

  return {
    packages: PKGList,
    selPackage: PKGList[nextType],
    curPackageData: memberPKG,
    packageType,
  }
}

/**
 * 获取用户套餐版本和信息
 * @returns {Promise<{
 *  version: 'free'|'advance_version'|'enterprise_version'|'enterprise_ultimate_version'|'enterprise_ultimate_free_version';
 *  data: object;
 * }>}
 */
export function checkMemberPKG(params) {
  return httpMemberPackageInfo(params)
    .then(({ data, status_code, status }) => {
      if (status_code === 1 && status === 200) {
        const { package_status, free_package_status } = data
        // 新版套餐试用版，优先级比新版套餐高
        if (free_package_status) {
          return {
            version: free_package_status.version,
            data,
          }
        }
        // 新版套餐
        if (package_status) {
          return {
            version: package_status.version,
            data,
          }
        }
        return {
          version: 'free',
          data,
        }
      }
    })
    .catch((error) => {
      console.log('httpMemberPackageInfo err: ', error)
      return Promise.reject(error)
    })
}
