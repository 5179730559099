import { render, staticRenderFns } from "./lib-main.vue?vue&type=template&id=6ee06632&scoped=true&"
import script from "./lib-main.vue?vue&type=script&lang=js&"
export * from "./lib-main.vue?vue&type=script&lang=js&"
import style0 from "./lib-main.vue?vue&type=style&index=0&id=6ee06632&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ee06632",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonNewHeaderOut: require('/builds/wj/fe/wenjuan-homesite/components/common/new-header-out.vue').default,CommonImage: require('/builds/wj/fe/wenjuan-homesite/components/common/image.vue').default,CommonDevModeWrap: require('/builds/wj/fe/wenjuan-homesite/components/common/dev-mode-wrap.vue').default})
