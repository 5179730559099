import axios from 'axios'
import { Message } from '@wenjuan/ui2'
import { signatureFunc_old, genBaseURL } from './helper'
import ApiRuleRedirect from './api-redirect.js'
import { get as getCookie } from '~/utils/cookie'
import WATCHPIPE from '~/api/watch-pipe.js'

// 服务端请求的logger，用于记录请求的状态
// 仅当服务端并且环境变量开启log功能，才初始化logger文件，否则客户端代码会报错
let logger

if (process.server && process.env.NUXT_LOG_REQUEST) {
  logger = require('~/utils/logger/request').logger
}

let setupRegisterOrLoginTrack = null
const ThirdPartyStat = () => import('@/plugins/third-party-stat.client.js')
const isServer = isNodeServer()

if (!isServer) {
  ThirdPartyStat()
    .then((data) => {
      setupRegisterOrLoginTrack = data.setupRegisterOrLoginTrack
    })
    .catch((e) => {
      console.log('ThirdPartyStat加载出错')
      console.log(e)
    })
}

const apiInstance = axios.create({
  // 开启本地反向代理，只需要把地址转为当前页面的全域名地址拼接/proxy/即可
  // 只代理了浏览器端请求，服务端请求不会跨域，避免代理耗时
  // 注意：如果你浏览器打开的是local.wenjuan.com，那么这里就得一致
  //       反之localhost也一样，否则会跨域阻止（跨域是根据域名判断）

  baseURL: process.env.NODE_ENV === 'development' ? genBaseURL('/proxy') : genBaseURL(),
  timeout: 5000,
})

// (POST)data内新增多个特殊字段用于转换data
// __FORMDATA__：true，会将axios默认的json编码换成application/x-www-form-urlencoded，已对数据做了序列化
// __UPLOAD_FORMDATA__：true，会将axios默认的json编码换成multipart/form-data，已对数据做了序列化
// __XSRF__：true，会在data新增一个xsrf的cookie字段
//
// config新增__SIGNATURE__用于自定义请求参数签名
// __SIGNATURE__: ，会使用自定义的签名函数，如果不传，则使用主站旧版签名方式
apiInstance.interceptors.request.use(
  (config) => {
    const { method, __SIGNATURE__ } = config
    config = __SIGNATURE__ ? __SIGNATURE__(config) : signatureFunc_old(config)
    if (method !== 'get') {
      const { __FORMDATA__, __XSRF__, __UPLOAD_FORMDATA__ } = config.data
      // 转换axios请求编码为form表单
      if (__FORMDATA__) {
        delete config.data.__FORMDATA__
        const formParams = new URLSearchParams()
        Object.keys(config.data).forEach((key) => {
          formParams.append(key, config.data[key])
        })
        config.data = formParams
      }
      // axios请求带上xsrf cookie
      if (__XSRF__) {
        const secure_key = getCookie('_xsrf') || ''
        if (secure_key) {
          config.data._xsrf = secure_key
        }
        delete config.data.__XSRF__
      }
      // 转换axios请求编码为form-data表单文件
      if (__UPLOAD_FORMDATA__) {
        delete config.data.__UPLOAD_FORMDATA__
        const formData = new FormData()
        Object.keys(config.data).forEach((key) => {
          formData.append(key, config.data[key])
        })
        config.data = formData
      }
    }
    WATCHPIPE.add('axios')

    return config
  },
  (error) => {
    return Promise.resolve(error)
  }
)

apiInstance.interceptors.response.use(
  // 2xx的http statusCode触发
  (response) => {
    const { data, config } = response
    const { err_code, err_msg, error_msg, status_code } = data || {}
    // 接口操作成功，返回data.data具体数据
    // 这是接口是不是放开
    ApiRuleRedirect({ errorCode: err_code, url: config.url, noRedirect: config.noRedirect })
    // 接口联通 但是错误处理
    if (err_code || (err_msg && typeof err_msg !== 'object') || error_msg || status_code === 0) {
      if (logger) {
        logger.warn(`${config.method.toUpperCase()} ${config.url}   Response Error ${err_msg || error_msg}`)
      }
      //  下面优先获取到接口是不是需要错误提示
      const {
        config: { useErrorTip = false },
      } = response
      if (useErrorTip) {
        Message?.closeAll()
        Message({
          dangerouslyUseHTMLString: true,
          message: err_msg || 'Error',
          type: 'error',
          duration: 2 * 1000,
          showClose: true,
        })
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        data: response.data,
        headers: response.headers,
      })
    } else {
      if (logger) {
        logger.info(`${config.method.toUpperCase()} ${config.url}   Response Ok`)
      }
      WATCHPIPE.sub('axios', config.url)
      // wenjuan-bussiness 的包的接口有特殊返回值  unUseTransition 为真的时候返回原始数据

      // 处理埋点
      setSdkTrack()

      if (config.unUseTransition) {
        return response.data
      } else {
        return {
          status_code: response?.data?.status_code || 0,
          status: response?.data?.status || 0,
          data: typeof response.data.data === 'object' ? response.data.data : response.data,
          headers: response.headers,
          originData: response.data, // 返回原始数据格式
        }
      }
    }
  },
  // 非2xx的http statusCode触发
  (error) => {
    if (logger) {
      if (error.response) {
        const { config, status, statusText } = error.response
        logger.error(`${config.method.toUpperCase()} ${config.url}   Request Error ${status} ${statusText}`)
      } else if (error.request) {
        logger.error(
          `${error.request.method.toUpperCase()} ${error.request.path}   Request Error no response was received`
        )
      }
    }
    WATCHPIPE.sub('axios')
    // https://github.com/nuxt/nuxt.js/issues/6294
    // // 一定要注意这个，当error.response为null时，不能直接reject，否则会造成nuxt生产环境内存溢出！
    return Promise.reject(new Error(error))
  }
)

async function setSdkTrack() {
  try {
    if (!isServer) {
      if (setupRegisterOrLoginTrack) {
        await setupRegisterOrLoginTrack()
      } else {
        await ThirdPartyStat()
          .then((data) => {
            data?.setupRegisterOrLoginTrack?.()
          })
          .catch((e) => {
            console.log('ThirdPartyStat')
            console.log(e)
          })
      }
    }
  } catch (error) {
    console.log('处理埋点出错')
    console.log(error)
  }
}

function isNodeServer() {
  return process !== undefined && Object.prototype.toString.call(process) === '[object process]'
}

const $get = (url, params, options) => apiInstance({ method: 'get', url, params, ...options })

// download 下载时候设置为真
const $post = (url, data, options) => {
  const _option_ = {
    download: false,
    ...options,
  }
  // 具备前后顺序 覆盖
  return apiInstance({
    method: 'post',
    url,
    data,
    responseType: _option_.download ? 'blob' : 'json',
    ..._option_,
  })
}

export { $get, $post }
export default apiInstance
