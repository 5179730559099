
import { WenwenAi } from '@wenjuan/business'
import { mapState, mapMutations } from 'vuex'
import { formatUserPackageInfo } from '~/utils/tools.js'
import { uploadImgToQiniuV2 } from '~/utils/qinu-upload-tools/index.js'
import { checkMemberPKG } from '~/utils/version-package/package-about.js'
import { checkMemerPKGPurchase } from '~/components/list/components/card/utils'
import { httpGetSceneList } from '~/api/list/create.js'

export default {
  name: 'LibAiCreate',
  components: {
    WenwenAi,
  },
  data() {
    return {
      sceneData: [],
      wenwenAi: {
        visible: false,
        projectId: '',
        conversationType: 'create_project',
        extraInfo: {},
      },
    }
  },
  computed: {
    ...mapState(['loginStatus']),
    surveySceneInfo() {
      return this.sceneData.find((item) => item.name_en === 'survey') || {}
    },
  },
  mounted() {
    this.getSceneInfo()
  },
  methods: {
    ...mapMutations(['setLoginStatus']),
    // 获取场景默认属性
    getSceneInfo() {
      // 未登录 不调用接口
      if (!this.loginStatus.loggedIn) return
      httpGetSceneList()
        .then((res) => {
          if (res.status == 200 && res.status_code == 1) {
            this.sceneData = res.data.scene_list || []
          }
        })
        .catch((e) => {
          console.log('获取场景列表失败')
          console.log(e)
        })
    },
    uploadImgToQiniuV2,
    openAiCreate() {
      if (!this.loginStatus.loggedIn) {
        // 没有登入的情况下
        this.setLoginStatus({
          show: true,
          nextUrl: () => {
            this.setLoginStatus({
              loggedIn: 1,
            })
            this.$nextTick(() => {
              location.reload()
            })
          },
        })
        return
      } else {
        this.wenwenAi.extraInfo.projectFuncId = this.surveySceneInfo.p_func_id
        this.wenwenAi.visible = true
      }
    },
    // 检查当日项目创建数是否超限
    async checkProjectLimit() {
      const res = await checkMemberPKG()
      const overLimit = await checkMemerPKGPurchase(res, this.openPackageDialog)

      return overLimit
    },
    // 打开购买弹窗
    openPackageDialog() {
      const data = this.$store.state.member.packageInfo
      const { nextVersion } = formatUserPackageInfo(data)
      this.$store.commit('setPackageDialog', {
        show: true,
        activeVersion: nextVersion,
        lowestVersion: nextVersion,
        source: '数据限制',
      })
    },
  },
}
