
  export default {
    name: 'ImageLoading',
    props: {
      src: {
        type: String,
        default: '',
      },
      width: {
        type: String,
        default: '',
      },
      height: {
        type: String,
        default: '',
      },
      alt: {
        type: String,
        default: '',
      },
      maxHeight: {
        type: String,
        default: '',
      },
      // 占位高度
      placeHeight: {
        type: String,
        default: 'inherit',
      },
      // 防止分裂
      preventSplit: {
        type: Boolean,
        default: false,
      },
      preventSplitColor: {
        type: String,
        default: '#f1f2f3;',
      },
      // 报错时候的图片
      errorImage: {
        type: String,
        default: ';',
      },
    },
    data() {
      return {
        loading: !this.preventSplit,
        plety: '',
        setpCount: 0,
        error: () => {
          if (this.errorImage && this.setpCount === 0) {
            this.plety = this.errorImage
            this.setpCount += 1
          } else {
            this.plety = ''
            this.setpCount = 0
          }
        },
      }
    },
    computed: {
      computedStyle() {
        const { height, width, maxHeight } = this
        return Object.filter({ height, width, maxHeight }, (value) => value || value === 0)
      },
    },
    watch: {
      src(cval) {
        if (cval) {
          this.loading = !this.preventSplit
          this.plety = ''
          this.setpCount = 0
        }
      },
    },
  }
