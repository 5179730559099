// 项目类型对应pType
export const projectTypeEnums = {
  SURVEY: 'survey',
  FORM: 'form',
  ASSESS: 'assess',
  VOTE: 'vote',
  SATIS: 'satis',
  SATISFACTION: 'satisfaction',
  EVALUATION: 'evaluation',
  PAY: 'pay',
  APPRAISE: 'appraise',
  KNOWLEDGE: 'knowledge',
  SCHOLARISM: 'scholarism',
  NPS: 'NPS',
  EDU: 'edu',
  VIDEO: 'video',
}

export const projectConfig = {
  [projectTypeEnums.SURVEY]: {
    pType: 0,
    name: '问卷',
  },
  [projectTypeEnums.FORM]: {
    pType: 1,
    name: '表单',
  },
  [projectTypeEnums.ASSESS]: {
    pType: 2,
    name: '考试',
  },
  [projectTypeEnums.VOTE]: {
    pType: 0,
    name: '投票',
  },
  [projectTypeEnums.SATIS]: {
    pType: 0,
    name: '满意度',
  },
  [projectTypeEnums.SATISFACTION]: {
    pType: 0,
    name: '满意度',
  },
  [projectTypeEnums.EVALUATION]: {
    pType: 2,
    name: '测评',
  },
  [projectTypeEnums.PAY]: {
    pType: 1,
    name: '在线收款',
  },
  [projectTypeEnums.APPRAISE]: {
    pType: 0,
    name: '360度评估',
  },
  [projectTypeEnums.KNOWLEDGE]: {
    pType: 2,
    name: '竞赛',
  },
  [projectTypeEnums.SCHOLARISM]: {
    pType: 1,
    name: '竞赛',
  },
  [projectTypeEnums.NPS]: {
    pType: 0,
    name: 'NPS',
  },
  [projectTypeEnums.EDU]: {
    pType: 0,
    name: '学术',
  },
}

// 维护一个前端的sceneType

export function getSceneNameStyle(pType, sceneType) {
  // 考试
  if (pType === 2 && (sceneType === 'traineval' || !sceneType)) {
    return 'assess'
  }
  // 表单
  if (pType === 1 && (sceneType === 'apply' || !sceneType)) {
    return 'form'
  }

  // 问卷
  if (!pType && (sceneType === 'brand' || !sceneType)) {
    return 'survey'
  }

  // 其他场景
  return sceneType
}

//  场景tag的颜色集合  针对 CommonTypeTag 组件使用
/**
 * @description: 描述颜色集合面板
 *
 * [{
 * "title":"问卷", "en_title":"survey","scene_number":1},
 * {"title":"考试","en_title":"assess","scene_number":5},
 * {"title":"表单","en_title":"form","scene_number":4},
 * {"title":"投票","en_title":"vote","scene_number":3},
 * {"title":"满意度","en_title":"satisfaction","scene_number":2},
 * {"title":"竞赛","en_title":"scholarism","scene_number":12},
 * {"title":"收款","en_title":"pay","scene_number":7},
 * {"title":"360","en_title":"appraise","scene_number":8}]
 */
export const scenceType = {
  问卷: {
    name: 'survey',
    number: 1,
    label: '问卷',
  },
  survey: {
    name: 'survey',
    number: 1,
    label: '问卷',
  },
  校园: {
    name: 'school',
    number: 1,
    label: '校园',
  },
  school: {
    name: 'school',
    number: 1,
    label: '校园',
  },
  项目: {
    name: 'project',
    number: 1,
    label: '项目',
  },
  project: {
    name: 'project',
    number: 1,
    label: '项目',
  },
  NPS: {
    name: 'NPS',
    number: 1,
    label: 'NPS',
  },
  评价: {
    name: 'comment',
    number: 1,
    label: '评价',
  },
  comment: {
    name: 'comment',
    number: 1,
    label: '评价',
  },
  投票: {
    name: 'vote',
    number: 2,
    label: '投票',
  },
  vote: {
    name: 'vote',
    number: 2,
    label: '投票',
  },
  考试: {
    name: 'assess',
    number: 3,
    label: '考试',
  },
  assess: {
    name: 'assess',
    number: 3,
    label: '考试',
  },
  测评: {
    name: 'evaluation',
    number: 3,
    label: '测评',
  },
  evaluation: {
    name: 'evaluation',
    number: 3,
    label: '测评',
  },
  表单: {
    name: 'form',
    number: 4,
    label: '表单',
  },
  form: {
    name: 'form',
    number: 4,
    label: '表单',
  },
  360: {
    name: 'appraise',
    number: 5,
    label: '360',
  },
  appraise: {
    name: 'appraise',
    number: 5,
    label: '360',
  },
  满意度: {
    name: 'satisfaction',
    number: 6,
    label: '满意度',
  },
  satisfaction: {
    name: 'satisfaction',
    number: 6,
    label: '满意度',
  },
  竞赛: {
    name: 'knowledge',
    number: 6,
    label: '竞赛',
  },
  knowledge: {
    name: 'knowledge',
    number: 6,
    label: '竞赛',
  },
  学术: {
    name: 'edu',
    number: 7,
    label: '学术',
  },
  edu: {
    name: 'edu',
    number: 7,
    label: '学术',
  },
  收款: {
    name: 'pay',
    number: 8,
    label: '收款',
  },
  pay: {
    name: 'pay',
    number: 8,
    label: '收款',
  },
  广告: {
    name: 'pay',
    number: 9,
  },
  互动视频: {
    name: 'video',
    number: 10,
    label: '视频',
  },
  video: {
    name: 'video',
    number: 10,
    label: '视频',
  },
  // 状态
  发布中: {
    name: 'publish',
    number: 22,
  },
  未发布: {
    name: 'unpublish',
    number: 21,
  },
  收集中: {
    name: 'collect',
    number: 22,
  },
  审核不通过: {
    name: 'unchecked',
    number: 23,
  },
  审核中: {
    name: 'checking',
    number: 24,
  },
  default: {
    name: 'default',
    number: 1,
  },
}
