import api, { $get, $post } from '../base'

/**
 * 搜索框默认占位符、默认搜索词
 * @returns
 */
export function searchPlaceholder() {
  return api.get('/api/project_lib/search/placeholder/')
}

/**
 * 搜索框热门搜索词
 * @returns
 */
export function searchTrendingKeywords() {
  return api.get('/api/project_lib/search/trending_keywords/')
}

/**
 * 问卷类型描述映射
 * @returns
 */
export function sceneTypeDesc() {
  return api.get('/api/project_lib/search/scene_type_desc/')
}

/**
 * 搜索框获取查询联想词
 * @param {string} keyword
 * @returns
 */
export function searchLazyAssociate(keyword) {
  return api.get('/api/project_lib/search/associative_words/', {
    params: {
      keyword,
    },
  })
}

// 模板中心首页
export const getLibraryList = (params) => $get('/api/project_lib/projectlib/list/', params)
/** 底部卡片 */

// 获取项目类型列表
export const getSceneType = function (instance, is_pc = 1) {
  return $get('/api/project_lib/search/scene_type_desc/', { is_pc }).then(({ data }) => {
    ;(instance || this).$store.commit('setSceneTypeList', data)
    return data
  })
}

// 获取foot-card-list 的模板中心的底部三个卡片
export const getRecomment = () => $get('/api/project_lib/lib_topic/simple_recomment_list/')
// 获取最新模板列表
export const getSimpleLastest = () => $get('/api/project_lib/projectlib/simple_lastest_list')
// 获取历史模板
export const getSimpleHistory = () => $get('/api/project_lib/project/simple_history_list')

// 获取模板相关文章
export const getRelatedArticle = (library_id) => $get('/api/project_lib/library/related_article/', { library_id })
// 获取模板相关专题列表
export const getTopic = (library_id) => $get('/api/project_lib/topic/related_topic/', { library_id })
// 获取模板相关历史项目
export const getRelatedSeoProject = (library_id) =>
  $get('/api/project_lib/library/related_seo_project/', { library_id })

/** 精选模板 */
export const extactTopicList = () => $get('/api/project_lib/topics/list')

/** 后台设置模板首页的专题列表 */
export const getCustomList = (page = 1) =>
  $get('/api/project_lib/projectlib/customized_library_list', {
    page,
    page_size: 10,
  })

// 获取模板首页的推举专题
export const getRecomendation = (params = { page: 1, page_size: 6 }) =>
  $get('/api/project_lib/lib/recommendation/', params)
/**
 * 获取推荐专题列表
 * @param {{ page: number; pageSize: number}} opt
 * @returns
 */
export function topicList({ page = 1, pageSize = 25 } = {}) {
  return $get('/api/project_lib/topic/topic_list/', {
    page,
    page_size: pageSize,
  })
}

// 相关接口
export const getRelatedLibrary = (params) => $get('/api/project_lib/library/related_library/', params)

/**
 * 获取专题详情
 * @param {string} topicId
 * @param {{ page: number; pageSize: number}} opt
 * @returns
 */
export function topicDetail(topicId) {
  return $get(`/api/project_lib/topic/topic_detail/${topicId}`, {
    page: 1,
    page_size: 25,
  })
}

/**
 * 获取最新模板列表
 * @param {{ page: number; pageSize: number; scene: string}} opt
 * @returns
 */
export function latestList({ page = 1, pageSize = 25, scene = 0 } = {}) {
  return api.get('/api/project_lib/projectlib/latest_list/', {
    params: {
      scene,
      page,
      page_size: pageSize,
    },
  })
}

/**
 * 获取历史项目列表
 * @param {{ page: number; pageSize: number; funcId: string}} opt
 * @returns
 */
export function historyList({ page = 1, pageSize = 25, funcId = '' } = {}) {
  return api.get('/api/project_lib/project/history_list', {
    params: {
      func_id: funcId,
      page,
      page_size: pageSize,
    },
  })
}

/** 获取引入模板接口 */
export const importCardItem = (data) => $post('/edit/ajax/survey_copy/', data)

/**
 * 轮询接口，轮询获取复制异步任务是否完成
 * @param {*}
 */
export function httpGetAsyncCopyStatus(params) {
  const pid = params.pid
  return api.get(`/edit/api/get_copy_project_status/${pid}/`)
}

/**
 * @method 收藏或取消收藏行为
 */
export const collectActive = (data, options) => $post('/api/project_lib/library/collect/', data, options)

// 精选专题的详细主题内容
export const topicInfoHeader = (url) => $get(`/api/project_lib/topic/activity_topic/${url}/`)

export const gettopBodyTabsContent = (params) => $get('/app_api/topic/libs/', params)

// 是否显示推荐模板
export const isShowRecommendation = () => $get('/api/project_lib/recommendation_library_visibility')

// 相关配置
export const sceneConfig = (scene) => $get('/api/project_lib/projectlib/index_config/', { scene })

// 免费经典模板
export const freeTemplateList = (params) => $get('/api/project_lib/projectlib/index_default/', params)

// 发送付费套餐线索
export function sendPayClue(data) {
  return api.post('/api/cms_pay_clue/pay_clue/create/', data)
}

export const libraryCountDetail = () => $get('/api/project_lib/library/count_detail/')
