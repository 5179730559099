import { Message, MessageBox } from '@wenjuan/ui2'
import { tools } from '@wenjuan/business'
import { openDialog } from '../dialog/index'
import editPop from '../dialog/edit'
import { checkPermission } from '~/api/list'
import { httpMemberPackageInfo, getUserInfo } from '~/api/common.js'
import { projectAnswersCount, checkAppraiseStatus } from '@/api/list'
import { projectTypeEnums, projectConfig } from '~/components/list/enums/project.js'
import { get as getCookie } from '~/utils/cookie'
import { sendPayClue } from '~/api/library-center/common.js'

// 腾讯验证码appid
const TencentCaptchaAppId = process.env.TENCENT_CAPTCHA_APPID

// 用户套餐版本的枚举，按照数组index升序
export const MEMBER_PKG_TYPE_ENUM = [
  'free',
  'advance_version',
  'enterprise_version',
  'enterprise_ultimate_version',
  'enterprise_ultimate_free_version',
]

/**
 * 判断当前账号空间角色
 *
 * role:  0 => 个人空间账号 1 => 企业空间子账号 2 => 企业空间管理员.
 * @returns {Promise<{role: 0|1|2; extra: object }>} 返回一个promise，role表示角色类型
 */
export async function checkSpaceMemberRole() {
  try {
    const { data } = await getUserInfo()
    const { member_type } = data
    // 如果使用返回的promise，则不执行cb
    return {
      role: member_type,
      extra: data,
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * 检查成员权限
 * @param {string} projectId
 * @param {'1'|'2'|'3'} permissionType 1表示编辑页检查，2表示发布页检查，3表示报表页检查
 * @returns
 */
export async function checkMemberPermissions(
  projectId,
  permissionType = '1',
  { defaultErrorMessage = '您没有权限使用，请联系项目所有者' } = {}
) {
  try {
    await checkPermission({ project_id: projectId, permission_type: permissionType })
  } catch (error) {
    if (typeof error === 'object') {
      const { data } = error
      const { status, err_code, ent_id } = data
      if (status !== 200) {
        Message.info('请稍后再试')
        return Promise.reject(error)
      }
      if (err_code === 20201 || err_code === 20230) {
        // 企业成员被禁用,固定去往成员禁用页面
        window.location.href = `/enterprise/forbid/?ecode=${err_code}&ent_id=${ent_id || ''}`
        return Promise.reject(error)
      }
      if (err_code === 20229 || err_code === 20228) {
        // 用户未加入该企业||当前用户所属企业不存在
        window.location.href = `/enterprise/error/?ecode=${err_code}`
        return Promise.reject(error)
      }
      Message.error(defaultErrorMessage)
    }
    return Promise.reject(error)
  }
}

/**
 * 新建/复制项目前，检测当前账号套餐是否已超出项目上限
 * @param {{
 *  version: string;
 *  data: Record<string, any>;
 * }} pkgData 从checkMemberPKG方法返回的数据
 * @returns {Promise<boolean>} hasOverflow 是否超出
 */
export async function checkMemerPKGPurchase(pkgData, useFunc) {
  const { version, data: memberInfo } = pkgData
  const { daily_project_left_count } = memberInfo
  // 先校验当前用户套餐版本和每日可创建项目限制数量
  // 如果可创建项目数量等于0，则弹窗提示，根据版本，判断是否可以升级套餐
  if (daily_project_left_count === 0) {
    if (version.startsWith('enterprise_ultimate_')) {
      MessageBox.confirm('检测到您今日创建的项目数量已达到上限，请您改日创建。', '提示')
    } else {
      // 当有子账号的用户是用其子账号空间创建项目时，PC端的弹窗提示文案进行调整
      const { role } = await checkSpaceMemberRole()
      if (role === 1) {
        MessageBox.confirm(
          '检测到该空间今日创建的项目数量已达到上限，请您改日创建，或联系管理员升级套餐以提高此上限',
          '提示'
        )
        return true
      }
      MessageBox.confirm('检测到您今日创建的项目数量已达到上限，请您改日创建，或升级您的账号套餐以提高此上限', '提示', {
        confirmButtonText: '升级套餐',
        callback(action) {
          if (action === 'confirm') {
            // 埋点
            const versionLabels = ['基础版', '高级版', '企业版', '旗舰版', '旗舰版（试用）']
            const label = versionLabels[MEMBER_PKG_TYPE_ENUM.findIndex((type) => type === version)]
            useFunc?.()
            // window.location.href = '/senior_user'
          }
        },
      })
    }
    return true
  }
  return false
}

// 高级版以下套餐，打开升级套餐弹窗
export async function checkFreeVersion(type, projectId, isConcertProject, that) {
  const params = {}
  if (projectId) {
    params.project_id = projectId
  }
  const res = await httpMemberPackageInfo(params)
  let packageVersion = 'free' // 套餐版本
  const {
    super_account: superAccount,
    package_status: packageStatus,
    free_package_status: freePackageStatus,
  } = res?.data || {}
  // 新版套餐试用版，优先级比新版套餐高
  if (freePackageStatus) {
    packageVersion = freePackageStatus.version
  } else if (packageStatus) {
    // 新版套餐
    packageVersion = packageStatus.version
  }
  // 旧版高级套餐
  if (superAccount) {
    packageVersion = 'advance_version'
  }
  let msgContent = ''
  if (packageVersion === 'free') {
    msgContent = '您当前的账号套餐不支持该功能，您可以升级您的账号套餐以使用该功能。'
  }
  if (!msgContent) return true
  if (isConcertProject) {
    msgContent = '项目所属账号套餐不支持该功能，您可以联系管理员升级账号套餐以使用该功能'
    MessageBox.confirm(msgContent, '提示', {
      confirmButtonText: '确定',
      showCancelButton: false,
      closeOnClickModal: false,
    })
      .then(() => {})
      .catch(() => {})
    return
  }
  MessageBox.confirm(msgContent, '提示', {
    confirmButtonText: '升级套餐',
    cancelButtonText: '取消',
    closeOnClickModal: false,
  })
    .then(() => {
      // window.open('/senior_user/')
      // 购买弹窗
      upgradePackageDialog(type, that)
    })
    .catch(() => {})
}

/**
 * 打开购买弹窗并埋点
 * @param  type
 *  type： 应用类型
 *  that：
 */
export function upgradePackageDialog(type, that) {
  const pluginObj = {
    wxSignin: {
      equity: 'wx-sign-in',
      clueTag: '微信签到',
    },
    voteWall: {
      equity: 'voting-wall',
      clueTag: '投票墙',
    },
    customDraw: {
      equity: 'lottery',
      clueTag: '自定义抽奖',
    },
    quota: {
      equity: '',
      clueTag: '配额',
    },
  }
  // 发送线索埋点
  sendPayClue({
    clue_resource: '触发套餐卡点',
    clue_tag_list: [pluginObj[type].clueTag],
  })
  that.$store.commit('setPackageDialog', {
    show: true,
    activeVersion: 'advance_version',
    lowVersion: 'advance_version',
    activeEquity: pluginObj[type].equity,
    source: pluginObj[type].clueTag,
  })
}

export function pageJumpHandle(url, isNewPage = false) {
  isNewPage ? window.open(url, '_blank') : (window.location.href = url)
}

/**
 * 编辑项目
 * @param {{
 *  projectId: string;
 *  sceneType: string;
 * }} param
 *  projectId：项目id
 *  sceneType：场景类型
 */
export async function editProject({
  key,
  projectId,
  sceneType = 'survey',
  isNewPage = false,
  isNameList = false,
  pType,
}) {
  try {
    await checkMemberPermissions(projectId)
    const res = await projectAnswersCount(projectId, sceneType)
    const defaultName = pType === 2 ? '考试' : pType === 1 ? '表单' : '问卷'
    const sceneName = projectConfig[sceneType || 'survey']?.name || defaultName

    // 有答卷数据需确认，没有的话直接跳转答卷设计
    const {
      money_collect: moneyCollect,
      rspd_count: rspdCount,
      new_rspd_count: newRspdCount,
      pc_count: pcCount,
      proj_status: projStatus,
      have_contact_import: haveContactImport,
      signup_new_rspd_count: signupNewRspdCount, // 报名项目收集的数据份数
    } = res.data
    // projStatus(-1:永久删除, -2:已删除, 0:未发布, 1:收集中,  99:审核中; 100:审核不通过;)
    const url = getJumpToEditUrl(sceneType, isNameList)
    const hrefUrl = `${url}${projectId}?scene=${sceneType}`
    // 进入到分享页面（-1:永久删除, -2:已删除,99:审核中; 100:审核不通过）
    if ([-1, -2, 100].includes(projStatus)) {
      let publishUrl = '/publish/share/'
      // 编辑名单的时候，审核中去名单页面
      // publishUrl = isNameList ? `/edit/appraise/` : `/publish/survey/`
      if (sceneType === 'school') {
        publishUrl = '/chess/publish/'
      }
      pageJumpHandle(`${publishUrl}${projectId}`, isNewPage)
      return
    } else if (projStatus === 99) {
      // status 项目状态为99 的时候
      const _url_ = {
        edit: `/edit/survey/${projectId}`,
        nameList: hrefUrl,
      }[key]
      if (_url_) return pageJumpHandle(_url_, isNewPage)
    }

    const moneyCollectText = `在您修改${sceneName}时，${sceneName}会暂停收集，但不影响正在支付的用户，您需要在修改完后再次发布${sceneName}才可继续进行收集。`
    // 360场景时，进入编辑页时跳转到评价关系页 或 判断 飞行棋

    let content = ''
    let title = '修改提示'
    let popType = 0
    let isGetPreDownload = false

    // 名单
    if (isNameList) {
      pageJumpHandle(hrefUrl, isNewPage)
      return
    }
    // 老数据或者新数据不为0的情况
    if (rspdCount !== 0 || newRspdCount !== 0) {
      if (pcCount !== 0) {
        popType = newRspdCount !== 0 ? 1 : 2
        content =
          '修改将使你的项目暂停收集，你的样本服务订单也将被取消！完成修改后请重新发布问卷，至“发布项目→样本服务”重新下订单。'
        title = '修改确认'
      } else if (newRspdCount !== 0) {
        if (sceneType === 'appraise') {
          isGetPreDownload = true
        }
        if (sceneType === 'appraise') {
          content = `所有数据收集将被暂停，答题链接将无法收集数据，确认要暂停吗？您的问卷已收集${rspdCount}份数据，如增删题目或评价关系。会同步删除相关数据，不可恢复，请慎重操作。确定后系统会自动备份当前数据到“我的下载”，如需请前往查看。`
        } else if (sceneType === 'vote') {
          // 投票2.0，1拖3数据处理
          content = `您的${sceneName}已收集 ${rspdCount} 份数据，报名新收集${signupNewRspdCount}份数据，现在进行题目删改可能会影响已收集的数据。`
        } else if (haveContactImport === 1) {
          content = `1.您的${sceneName}已收集 ${rspdCount} 份数据，现在进行题目删改可能会影响已收集的数据。<br />2.您的表单已同步至联系人，现在修改会取消同步，已导入的联系人不会删除，该项目后续新增的答卷信息不会再自动同步导入联系人中。`
        } else {
          content = `您的${sceneName}已收集 ${rspdCount} 份数据，现在进行题目删改可能会影响已收集的数据。`
        }
        if (moneyCollect === 'on') {
          content = `${content}<br/>${moneyCollectText}`
        }
        popType = 1
      } else if (signupNewRspdCount !== 0) {
        // 投票2.0，1拖3数据处理
        content = `您的${sceneName}已收集 ${rspdCount} 份数据，报名新收集${signupNewRspdCount}份数据，现在进行题目删改可能会影响已收集的数据。`
        popType = 1
      } else if (projStatus === 1) {
        content = `在您修改${sceneName}时，${sceneName}会暂停收集，您需要在修改完后再次发布${sceneName}才可继续进行收集，原${sceneName}链接不会改变。`
        if (moneyCollect === 'on') {
          content = moneyCollectText
        }
        popType = 2
      } else {
        pageJumpHandle(hrefUrl, isNewPage)
        return
      }

      pageJumpHandle(hrefUrl, isNewPage)
      // 显示弹窗
      // showPop({
      //   projectId,
      //   sceneType,
      //   content,
      //   title,
      //   popType,
      //   projStatus,
      //   isNewPage,
      //   isNameList,
      //   isGetPreDownload,
      // })
      return
    }

    // 未发布情况
    if (projStatus == 0) {
      pageJumpHandle(hrefUrl, isNewPage)
      return
    }

    // 收集中情况
    if (projStatus === 1) {
      if (pcCount != 0 && !isNameList) {
        title = '修改确认'
        content = `修改将使你的项目暂停收集，你的样本服务订单也将被取消！完成修改后请重新发布问卷，至“发布项目→样本服务”重新下订单。`
        popType = 2
      } else if (signupNewRspdCount !== 0) {
        // 投票2.0，1拖3数据处理
        content = `您的${sceneName}已收集 ${rspdCount} 份数据，报名新收集${signupNewRspdCount}份数据，现在进行题目删改可能会影响已收集的数据。`
        popType = 1
      } else if (!isNameList) {
        content = `在您修改${sceneName}时，${sceneName}会暂停收集，您需要在修改完后再次发布${sceneName}才可继续进行收集。`
        if (moneyCollect === 'on') {
          content = moneyCollectText
        }
        popType = 2
      } else {
        pageJumpHandle(hrefUrl, isNewPage)
        return
      }
      pageJumpHandle(hrefUrl, isNewPage)
      // 显示弹窗
      // showPop({
      //   projectId,
      //   sceneType,
      //   content,
      //   title,
      //   popType,
      //   projStatus,
      //   isNewPage,
      //   isNameList,
      // })
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export function getJumpToEditUrl(sceneType, isNameList) {
  let url = '/edit/survey/'
  switch (sceneType) {
    case 'appraise':
      if (isNameList) {
        url = '/edit/appraise/'
      }
      break
    case 'school':
      url = '/chess/edit/'
      break
    case 'video':
      // 互动视频场景
      url = '/edit/videoform/'
      break
  }
  return url
}

export function showPop({ projectId, sceneType, content, title = '提示', popType, projStatus, isNewPage, isNameList }) {
  try {
    // 打开弹窗操作
    openDialog(editPop, {
      props: {
        projectId,
        sceneType,
        content,
        title,
        popType,
        projStatus,
        isNewPage,
        isNameList,
      },
    })
  } catch (error) {}
}

/**
 * 360场景套餐版本检测，非旗舰版均弹窗
 *
 * @param {string} pkgVersion 通过checkMemberPKG返回的version字段
 * @param {string} title 弹窗标题
 * @param {string} entrance 埋点value
 * @returns
 */
export function checkUserPermission({
  pkgVersion = '',
  title = '',
  entrance = '',
  showBtn = true,
  confirmCal = () => {},
}) {
  if (PRIVATE_MODE) {
    // 部署版
    return true
  }
  if (pkgVersion.startsWith('enterprise')) {
    return true
  }
  const versionTxt = pkgVersion === 'advance_version' ? '高级版' : '基础版'
  MessageBox.confirm(title, '提示', {
    confirmButtonText: showBtn ? '升级套餐' : '我知道了',
    cancelButtonText: '我知道了',
    showCancelButton: showBtn,
    callback(action) {
      if (action === 'confirm' && showBtn) {
        confirmCal()
        // window.location.href = '/pro/'
      }
    },
  })
  return false
}

/**
 * 检查360项目是否可编辑
 * @param {string} pid
 * @param {string} sceneType
 * @returns
 */
export async function checkAppraiseCanSetting(pid, sceneType) {
  try {
    if (sceneType != 'appraise') {
      return
    }
    const { data } = await checkAppraiseStatus(pid)
    if (data.in_processing) {
      Message.info('数据处理中，请稍候')
      return Promise.reject(new Error('360项目正在处理中'))
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

// 获取高级应用是否开启
function getPluginOpenList(pluginAppList, manualScore, hasSearchSettings) {
  // 人工评分
  let manualScoreOpen = false
  if (manualScore == '1') {
    manualScoreOpen = true
  }
  const obj = {
    wxRedEnvelope: {
      isOpen: false,
    },
    voteWall: {
      isOpen: false,
    },
    wxSignin: {
      isOpen: false,
    },
    luckyDraw: {
      isOpen: false,
    },
    quota: {
      isOpen: false,
    },
    manualScoring: {
      isOpen: manualScoreOpen,
    },
    query: {
      isOpen: hasSearchSettings,
    },
  }
  pluginAppList.forEach((element) => {
    // 微信红包
    if (element === 'lucky_money') {
      obj.wxRedEnvelope.isOpen = true
    }

    // 投票墙
    if (element === 'vote_wall') {
      obj.voteWall.isOpen = true
    }
    // 微信签到
    if (element === 'wx_signin') {
      obj.wxSignin.isOpen = true
    }

    // 自定义抽奖
    if (element === 'lucky_draw') {
      obj.luckyDraw.isOpen = true
    }

    // 配额
    if (element === 'quota') {
      obj.quota.isOpen = true
    }
  })

  return obj
}

// 获取应用列表
export function getPlginList(
  sceneType,
  flyBook,
  pluginAppList = [],
  manualScore,
  hasSelectQuestion,
  pType,
  hasSearchSettings
) {
  const pluginOpenList = getPluginOpenList(pluginAppList, manualScore, hasSearchSettings)
  const isShowVoteWall =
    hasSelectQuestion && ![projectTypeEnums.FORM, projectTypeEnums.PAY, projectTypeEnums.VIDEO].includes(sceneType)

  // 展示不同的应用列表
  let pluginList = []
  let voteWallArr = []
  let wxSigninArr = []
  let luckyDrawArr = [] // 抽奖
  let quotaArr = [] // 配额
  let manualScoringArr = []
  let wxRedEnvelopeArr = [] // 微信红包

  // 互动视频暂不支持微信红包
  if (sceneType !== projectTypeEnums.VIDEO) {
    wxRedEnvelopeArr = [
      {
        type: 'wxRedEnvelope',
        content: '微信红包',
        isOpen: pluginOpenList.wxRedEnvelope.isOpen,
        mold: 'plugin',
      },
    ]
  }

  if (isShowVoteWall) {
    voteWallArr = [
      {
        type: 'voteWall',
        content: '投票墙',
        seniorIcon: 'icon-senior',
        isOpen: pluginOpenList.voteWall.isOpen,
        mold: 'plugin',
      },
    ]
  }

  const queryArr = [
    // 项目列表按应用筛选需求移除此项
    // {
    //   type: 'query',
    //   content: '对外数据查询',
    //   isOpen: pluginOpenList.query.isOpen,
    //   mold: 'plugin',
    // },
  ]

  // 表单场景/非飞书应用中显示微信签到
  if ([projectTypeEnums.FORM, projectTypeEnums.PAY, projectTypeEnums.VIDEO].includes(sceneType) && !flyBook) {
    wxSigninArr = [
      {
        type: 'wxSignin',
        content: '微信签到',
        seniorIcon: 'icon-senior',
        isOpen: pluginOpenList.wxSignin.isOpen,
        mold: 'plugin',
      },
    ]
  }

  // 互动视频暂不支持自定义抽奖
  if (sceneType !== projectTypeEnums.VIDEO) {
    luckyDrawArr = [
      {
        type: 'luckyDraw',
        content: '自定义抽奖',
        seniorIcon: 'icon-senior',
        isOpen: pluginOpenList.luckyDraw.isOpen,
        mold: 'plugin',
      },
    ]
  }

  quotaArr = [
    {
      type: 'quota',
      content: '配额',
      seniorIcon: 'icon-senior',
      isOpen: pluginOpenList.quota.isOpen,
      mold: 'plugin',
    },
  ]

  // 考试测评场景
  if (pType === 2) {
    manualScoringArr = [
      // 项目列表按应用筛选需求移除此项
      // { type: 'manualScoring', content: '人工评分', isOpen: pluginOpenList.manualScoring.isOpen, mold: 'plugin' },
    ]
  }

  pluginList = [
    ...manualScoringArr,
    ...queryArr,
    ...wxRedEnvelopeArr,
    ...luckyDrawArr,
    ...quotaArr,
    ...voteWallArr,
    ...wxSigninArr,
  ]

  // 部署版
  if (process.env.PRIVATE_MODE) {
    pluginList = [...manualScoringArr, ...queryArr]
  }

  // 360场景
  if (sceneType === projectTypeEnums.APPRAISE) {
    pluginList = []
  }
  return pluginList
}

// 格式化状态
function formatStatus(status) {
  var contentObj = {
    0: {
      content: '发布',
      type: 'publish',
    },
    2: {
      content: '发布',
      type: 'publish',
    },
    // 审核流程优化，审核中可暂停
    99: {
      content: '暂停',
      type: 'noPublish',
    },
    100: {
      content: '审核不通过',
      type: 'publish',
    },
  }
  return (
    contentObj[status] || {
      content: '暂停',
      type: 'noPublish',
    }
  )
}

// is_enterprise_admin:0 不是企业主 1:企业主
// space_type:1 个人空间 2: 企业空间
function authorityJudgment(data) {
  const {
    is_enterprise_admin: isEnterpriseAdmin,
    space_type: spaceType,
    current_user: { id: currentUserId } = {},
    createId,
    pageName,
  } = data || {}
  if (spaceType == 1 || spaceType == 2 || (currentUserId === createId && pageName !== 'Cooperation')) {
    return true
  }
  return false
}

// 处理文件夹
function folderHandle(folderList = [], fid, isEnterpriseAdmin, spaceType) {
  let folderName = '我的项目'
  if (isEnterpriseAdmin === 1 || (spaceType === 2 && isEnterpriseAdmin !== 1)) {
    folderName = '企业项目'
  }
  const defaultFolder = [
    {
      folder_name: folderName,
      // 移动至文件夹的选项得取到fid，保持和动态生成的文件夹格式一致, 见wenjuan-homesite\components\list\components\menu.vue
      // line：36 和line：113
      id: fid,
      iconName: 'icon-desk-top',
    },
  ]
  folderList.forEach((item) => {
    if (item.id !== fid) {
      defaultFolder.push(item)
    }
  })
  return defaultFolder
}

// 最后一个blankContent是否展示
function isHideLastBlank(data) {
  const {
    sceneType = 'survey',
    rspdCount = 0,
    status = 0,
    folderList = [],
    pageName,
    is_enterprise_admin: isEnterpriseAdmin,
    space_type: spaceType,
    current_user: { id: currentUserId } = {},
    createId,
  } = data || {}
  if (
    rspdCount <= 0 &&
    spaceType == 2 &&
    !isEnterpriseAdmin &&
    currentUserId !== createId &&
    pageName !== 'Cooperation'
  ) {
    return true
  }

  if (
    sceneType === 'school' &&
    pageName === 'Cooperation' &&
    !(folderList.length > 0 && authorityJudgment(data)) &&
    [99, 100].includes(status)
  ) {
    return true
  }

  return false
}

/**
 * @title 获取更多列表
 * @param {Object} data  = { pageName: 判断场景页}
 * @returns
 */
function getMoreList(data) {
  // 微应用环境隐藏在新标签页打开
  const isMicroEnv = ['isDingTalk', 'isFeishu', 'isWxwork'].includes(tools.$ENV.defineState().curMicro)
  const {
    sceneType = 'survey',
    rspdCount = 0,
    status = 0,
    folderList = [],
    pageName,
    flyBook,
    fid,
    is_enterprise_admin: isEnterpriseAdmin,
    space_type: spaceType,
    current_user: { id: currentUserId } = {},
    createId,
    quickAccess,
  } = data || {}
  const moreList = [
    {
      type: 'newTab',
      content: '在新标签页打开',
      isHide: isMicroEnv,
    },
    {
      type: formatStatus(status).type,
      content: formatStatus(status).content,
      isHide: !(status !== 100 && !((status == 0 || status == 2) && sceneType == 'school')),
    },
    {
      type: 'preview',
      content: '预览',
      isHide: flyBook,
    },
    {
      type: 'blankContent',
    },
    {
      type: 'copy',
      content: '创建副本',
      isHide: [99, 100].includes(status),
    },
    {
      type: 'saveTemplate',
      content: '另存为模板',
      isHide:
        !(spaceType == 1 || (spaceType == 2 && isEnterpriseAdmin == 1)) ||
        [99, 100].includes(status) ||
        sceneType === 'video',
    },
    {
      type: 'copyToOther',
      content: '以模板分享给他人',
      isHide: [99, 100].includes(status) || sceneType === 'video',
    },
    {
      type: 'collaboration',
      content: '协作',
      isHide: spaceType == 2 && !isEnterpriseAdmin && currentUserId !== createId,
      // 只有我的项目才有协作菜单，协作是企业协议
    },
    {
      type: 'blankContent',
      isHide:
        (pageName === 'Cooperation' || (spaceType == 2 && !isEnterpriseAdmin && currentUserId !== createId)) &&
        [99, 100].includes(status),
    },
    {
      type: 'addQuickLink',
      content: '添加到快速访问',
      isHide: quickAccess, // 只存在我的项目中
    },
    {
      type: 'removerQuickLink',
      content: '从快速访问移除',
      isHide: !quickAccess,
    },
    // 审核流程优化移除所有项目重命名入口
    // {
    //   type: 'rename',
    //   content: '重命名',
    //   isHide: [99, 100].includes(status),
    // },
    // {
    //   type: 'copy',
    //   content: '复制项目',
    //   isHide: [99, 100].includes(status),
    // },
    {
      type: 'move',
      content: '移动至',
      list: fid ? folderHandle(folderList, fid, isEnterpriseAdmin, spaceType) : folderList,
      level: 2,
      isHide: !(folderList.length > 0 && authorityJudgment(data)),
    },
    {
      type: 'report',
      content: '导出项目（word）',
      isHide: ['school', 'video'].includes(sceneType),
    },
    {
      type: 'blankContent',
      name: 'lastBlank',
      isHide: isHideLastBlank(data),
    },
    {
      type: 'emptyData',
      content: '清空数据',
      isHide: rspdCount <= 0,
    },
    {
      type: 'delete',
      content: '删除项目',
      isHide: spaceType == 2 && !isEnterpriseAdmin && currentUserId !== createId,
    },
    {
      type: 'outCollaboration',
      content: '退出协作',
      isHide: pageName !== 'Cooperation',
    },
  ]
  return moreList
}

function getFolderOperation(data) {
  const { isTop, is_enterprise_admin: isEnterpriseAdmin, space_type: spaceType } = data || {}
  return [
    {
      iconName: 'icon-more',
      type: 'folder-more',
      content: '',
      list: [
        {
          type: isTop === 1 ? 'cancleTop' : 'top',
          content: isTop === 1 ? '取消置顶' : '置顶',
        },
        {
          type: 'rename',
          content: '重命名',
        },
        {
          type: 'delete',
          content: '删除',
        },
        {
          type: 'collaboration',
          content: '协作',
          isHide: !(spaceType == 2 && isEnterpriseAdmin == 1),
        },
      ],
    },
  ]
}

function getProjectOperationMore(data) {
  const flyBook = getCookie('from_client') || ''
  const moreList = getMoreList({ ...data, flyBook })
  const more = [
    {
      iconName: 'icon-more',
      type: 'more',
      content: '',
      list: moreList,
    },
  ]
  return more
}

function getProjectOperation(data) {
  const flyBook = getCookie('from_client') || ''
  const {
    sceneType = 'survey',
    pluginAppList,
    manualScore,
    hasSelectQuestion,
    pType,
    hasSearchSettings,
    type,
  } = data || {}
  let operationList = []
  if (type === 'more') {
    operationList = getProjectOperationMore(data)
    return operationList
  }
  const pluginList = getPlginList(
    sceneType,
    flyBook,
    pluginAppList,
    manualScore,
    hasSelectQuestion,
    pType,
    hasSearchSettings
  )
  operationList = [
    {
      iconName: 'icon-make',
      type: 'make',
      content: '制作',
      list: [
        { type: 'nameList', content: '名单', isHide: sceneType !== projectTypeEnums.APPRAISE },
        {
          type: 'edit',
          content: '编辑',
        },
        {
          type: 'setting',
          content: '设置',
          isHide: sceneType === 'school',
        },
        {
          type: 'plugin',
          content: '应用',
          list: pluginList,
          level: 2,
          isHide: sceneType === projectTypeEnums.APPRAISE || sceneType === 'school',
        },
      ],
    },
    {
      iconName: 'icon-collect',
      type: 'collect',
      content: '收集',
      list: [
        {
          type: 'share',
          content: '分享',
        },
        {
          type: 'collect',
          content: '样本收集',
          isHide: sceneType === 'appraise' || sceneType === 'school' || process.env.PRIVATE_MODE,
        },
      ],
    },
    {
      iconName: 'icon-data',
      type: 'data',
      content: '数据',
      list: [
        {
          type: 'viewDataGeneral',
          content: '查看数据概况',
        },
        {
          type: 'viewRawData',
          content: '查看原始数据',
        },
        {
          type: 'viewReportData',
          content: sceneType === 'appraise' ? '查看评估报告' : '查看题目报表',
        },
      ],
    },
  ]
  if (type === 'table') {
    const moreList = getProjectOperationMore(data)
    operationList = [...operationList, ...moreList]
  }
  return operationList
}

// 获取操作内容
export function getOperationList(data) {
  const { itemType } = data || {}
  // 如果是文件夹
  if (itemType) {
    return getFolderOperation(data)
  } else {
    return getProjectOperation(data)
  }
}

// 腾讯验证
export function manMachineVerificationByTencent() {
  let passValidation, failValidation
  const verificationPromise = new Promise((resolve, reject) => {
    passValidation = resolve
    failValidation = reject
  })
  if (window.TencentCaptcha) {
    var captcha1 = new window.TencentCaptcha(TencentCaptchaAppId, (res) => {
      const data = {
        rand_str: res.randstr,
        ticket: res.ticket,
      }
      if (data.rand_str && data.ticket) {
        passValidation({ data, status: 'success' })
      } else {
        failValidation({ data: res, status: 'error' })
      }
    })
    captcha1.show()
  } else {
    passValidation({})
  }
  return verificationPromise
}
