export const state = () => ({
  raw: '', // cookie原始字符串
  formated: {}, // 经过格式化的键值对
})

export const mutations = {
  // 更新cookie数据
  parse(stateObj, payload = '') {
    stateObj.raw = payload
    const blockRule = /;\s*/g
    const keyValRule = /=/g
    const list = payload.split(blockRule).filter((item) => item.trim() !== '')
    const parserMap = {}
    for (const v of list) {
      const keyVal = v.split(keyValRule)
      const [key, val] = keyVal
      parserMap[key] = val
    }
    stateObj.formated = parserMap
  },
}
