// 在使用hmt时，全局使用window._hmt，目前无法通过ES Module使用

// 百度统计埋点
/* eslint-disable */
if (window) {
  window._hmt = window._hmt || []
  window._hmt.push(['_setAccount', 'f0408cb7d2a91c6071945ba170b9decc'])
}
(function () {
  const hm = document.createElement('script')
  hm.async = 1
  hm.src = '//hm.baidu.com/hm.js?f0408cb7d2a91c6071945ba170b9decc'
  const s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(hm, s)
})()
