
import {
  httpUpdateProjectStatus,
  httpUpdateAjaxProjectStatus,
  httpUpdateFlyingChessStatus,
  httpProjectArchive,
  getDownloadPreRequest,
} from '~/api/list/index'
import { getJumpToEditUrl, pageJumpHandle } from '~/components/list/components/card/utils'
import { checkMemberPKG } from '~/utils/version-package/package-about.js'

export default {
  name: 'EditPop',
  props: {
    // 项目id
    projectId: {
      type: String,
      default: '',
    },
    // 项目场景类型，英文
    sceneType: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '提示',
    },
    content: {
      type: String,
      default: '',
    },
    // 弹窗类型（确认处理不同事件）
    popType: {
      type: Number,
      default: 0,
    },
    projStatus: {
      type: Number,
      default: 0,
    },
    isNewPage: {
      type: Boolean,
      default: false,
    },
    isNameList: {
      type: Boolean,
      default: false,
    },
    isGetPreDownload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: true, // dialog开关
      activeTab: 'link', // 当前tab选中
      shareLink: '', // 分享链接
      receiveAccount: '', // 接收方账号
      isRequesting: false, // 请求loading
      toResult: false, // 展示结果
      requestLock: false,
    }
  },
  methods: {
    onClosed() {
      this.$emit('closed')
    },
    async primaryHandle() {
      // 判断360场景或飞行棋
      const { sceneType, projectId, popType, projStatus, isNameList } = this
      const linkUrl = `${getJumpToEditUrl(sceneType, isNameList)}${projectId}`
      switch (popType) {
        case 0:
          await this.changeProjectStatus({
            projectId,
            linkUrl,
            projStatus,
          })
          break
        case 1:
          await this.continueDesignProject(sceneType, projectId, linkUrl)
          break
        case 2:
          await this.continueDesignProjectZero(projectId, linkUrl)
          break
      }
      // 当异步操作完成后，关闭当前页弹窗
      this.dialogVisible = false
      // 如果新开页面，则刷新list页面
      if (this.isNewPage) {
        window.location.reload()
      }
    },
    // 更改项目状态
    async changeProjectStatus(params) {
      const { projectId, projStatus, linkUrl } = params
      if (this.requestLock) return
      this.requestLock = true
      try {
        await httpUpdateProjectStatus({
          proj_id: projectId,
          status: projStatus === 2 || !projStatus ? 0 : projStatus,
        })
        this.requestLock = false
        if (linkUrl) {
          pageJumpHandle(linkUrl, this.isNewPage)
        }
      } catch (error) {
        this.requestLock = false
      }
    },
    async continueDesignProject(sceneType, projectId, linkUrl) {
      if (sceneType == 'school') {
        // 更新飞行棋项目状态
        const formatParams = {
          data: {
            status: 0,
          },
        }
        await httpUpdateFlyingChessStatus(projectId, formatParams)

        pageJumpHandle(`/chess/edit/${projectId}`, this.isNewPage)

        return
      }

      try {
        if (this.requestLock) return
        this.requestLock = true
        const data = {
          proj_id: projectId,
          is_merge: 0,
        }
        // 处理其他项目
        // 归档
        httpProjectArchive(projectId, data).then(
          (res) => {
            this.requestLock = false
            const { result } = res?.data
            if (result === 'Success') {
              if (linkUrl) {
                pageJumpHandle(linkUrl, this.isNewPage)
              }
            } else if (result === 'Doing') {
              this.$message('正在处理中，请稍候！')
            }
          },
          () => {
            this.requestLock = false
          }
        )
        if (this.isGetPreDownload) {
          // 360场景且收集到数据时,自动调用报表源数据下载接口进行下载
          this.getPreDownload()
        }
      } catch (error) {
        this.requestLock = false
      }
    },

    async continueDesignProjectZero(projectId, linkUrl) {
      try {
        if (this.requestLock) return
        this.requestLock = true
        await httpUpdateAjaxProjectStatus({
          proj_id: projectId,
          status: 0,
        })
        this.requestLock = false
        if (linkUrl) {
          pageJumpHandle(linkUrl, this.isNewPage)
        }
      } catch (error) {
        this.requestLock = false
      }
    },
    // 报表源数据下载接口
    async getPreDownload(projectId) {
      const pkgData = await checkMemberPKG()
      // 360场景套餐版本检测，非旗舰版均弹窗
      const { version } = pkgData
      // 360场景，收集到数据暂停项目时，需要调报表自动下载的接口
      const params = {
        project_id: projectId,
        download_type: 'form_list',
        data_type: 'original',
        filter_count: '',
        version,
        file_type: 'xlsx',
        is_merge: true,
        template_source: 3,
        client_type: 1,
        custom_name: false,
      }
      getDownloadPreRequest(projectId, params)
    },
  },
}
