export default function (context) {
  function expire_at(days) {
    const expire = new Date()
    if (days == undefined) {
      days = 1
    }
    expire.setTime(expire.getTime() + 12 * 60 * 60 * 1000 * days)

    return expire.toUTCString()
  }

  function extract_query_params(val, pending_cookies) {
    if (val != null && typeof val !== 'undefined') {
      if (Array.isArray(val) && val.length > 0) {
        return val[0]
      } else {
        return val.split(' ')[0]
      }
    }
    return null
  }

  function make_cookie(key, value, expire) {
    let cookie_str = `${key}=${encodeURIComponent(value)};`

    if (!context.isDev) {
      cookie_str += 'Domain=.wenjuan.com;'
    }
    cookie_str += `Expires=${expire};`
    cookie_str += 'Path=/'

    return cookie_str
  }

  // 需要兼容static构建
  if (process.server && !process.static) {
    const { req, res, query } = context

    let protocol = 'https'
    if (context.isDev) {
      // 没有找到合适的判断http和https的地方，只能根据开发环境和正式环境来做一个区分
      protocol = 'http'
    }
    const full_url = `${protocol}://${req.headers.host}${req.url}`

    const pending_cookies = []
    const utm_source = extract_query_params(query.utm_source)
    if (utm_source != null) {
      pending_cookies.push(make_cookie('utm_source', utm_source, expire_at(0.5)))
    }
    const utm_extern = extract_query_params(query.utm_extern)
    if (utm_extern != null) {
      pending_cookies.push(make_cookie('utm_extern', utm_extern, expire_at(0.5)))
    }
    const utm_short_id = extract_query_params(query.utm_shortid)
    if (utm_short_id != null) {
      pending_cookies.push(make_cookie('utm_shortid', utm_short_id, expire_at(0.5)))
    }
    // Search Engine Marketing 相关
    const search_plan = extract_query_params(query.plan)
    if (search_plan != null) {
      pending_cookies.push(make_cookie('sem_plan', search_plan, expire_at(0.5)))
    }
    const sem_device = extract_query_params(query.device)
    if (sem_device != null) {
      pending_cookies.push(make_cookie('sem_device', sem_device, expire_at(0.5)))
    }
    // 百度ocpc
    if (query.bd_vid) {
      pending_cookies.push(make_cookie('baidu_ocpc_url', full_url, expire_at(0.5)))
    }
    // 小红书ocpc
    const click_id = extract_query_params(query.click_id)
    if (click_id) {
      pending_cookies.push(make_cookie('xhs_click_id', click_id, expire_at(0.007)))
    }
    // 360ocpc
    const qhclickid = extract_query_params(query.qhclickid)
    if (qhclickid != null) {
      pending_cookies.push(make_cookie('qhclickid', qhclickid, expire_at(0.007)))
    }
    // 搜狗ocpc
    if (query.sg_vid) {
      pending_cookies.push(make_cookie('sogou_ocpc_url', full_url, expire_at(0.007)))
    }
    // 360广告展示
    const source_id = extract_query_params(query.sourceid)
    if (source_id != null) {
      pending_cookies.push(make_cookie('so360_sourceid', source_id, expire_at(0.007)))
    }
    // 腾讯广点通(eqq)ocpc
    if (query.qz_gdt) {
      const value = `${query.qz_gdt};${full_url}`
      pending_cookies.push(make_cookie('eqq_ocpc', value, expire_at(0.007)))
    }
    // 巨量引擎(oceanengine) - 线索API
    if (query.adid && query.creativeid && query.creativetype && query.clickid) {
      // 4 个参数都有的时候，认为是巨量引擎线索API
      pending_cookies.push(make_cookie('oceanengine_link', full_url, expire_at(1)))
    }

    res.setHeader('Set-Cookie', pending_cookies)
  }
}
