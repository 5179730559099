import api from '@/api/base'

// 获取用户邮件、短信剩余额度
export function memberQuota() {
  return api.get('/member/left_quota')
}

// 获取账户余额
export function getBalance() {
  return api.get('/member/tmp/currency/info/')
}

// 在当前用户余额充足时，可以直接调用购买接口

// 短信购买
export function buyMsg(params) {
  return api.post('/member/buysms/', params)
}

// 邮件购买
export function buyEmail(params) {
  return api.post('/collect/mail_quota/packages/', params)
}

// 广告额度包购买
export function buyAD(params) {
  return api.post('/plugin/api/v1/answer_ad_free/buy/', params)
}

// 在用户余额不充足时，需要先完成充值操作

// 充值操作
export function recharge(params) {
  return api.post('/member/tmp/generate_order/', params)
}

// 支付结果确认
export function validatePay(params) {
  return api.post('/member/validatepayorder/', params)
}

// 判断用户身份类型
export function memberType() {
  return api.get('/api/enterprise/current_space/')
}

// 获取企业信息
export function getCorpInfo() {
  return api.get('/api/package/corp_info/')
}

// 转移额度
export function transfer(params) {
  return api.post('/api/package/transfer_collection_quota/', params)
}

// 获取用户上传空间大小
export function uploadSpace(is_get_personal = 0) {
  return api.get('/api/member/wallet/get_upload_space_size_quota/', {
    params: { is_get_personal },
  })
}

/**
 * 购买弹窗立即支付按钮逻辑更新 生成支付订单
 * @returns
 */
export function createOrder(params) {
  return api.post('/api/order/create/', { ...params })
}
