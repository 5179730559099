import Vue from 'vue'
import { quillEditor } from 'vue-quill-editor'

import * as Quill from 'quill'
import ImageResize from 'quill-image-resize-module'

Vue.component('QuillEditor', quillEditor)

const fontSizeStyle = Quill.import('attributors/style/size')
const fontStyle = Quill.import('formats/font')
// 添加白名单
fontSizeStyle.whitelist = [
  '8px',
  '9px',
  '10px',
  '11px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '22px',
  '24px',
  '26px',
  '28px',
  '36px',
  '48px',
  '72px',
  false,
] // false表示默认值
fontStyle.whitelist = ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial', 'sans-serif']
// drapImg
Quill.register('modules/imageResize', ImageResize)
// 注册
Quill.register(fontSizeStyle, true)
Quill.register(fontStyle, true)
