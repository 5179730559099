import { render, staticRenderFns } from "./member.vue?vue&type=template&id=48bdc04e&scoped=true&"
import script from "./member.vue?vue&type=script&lang=js&"
export * from "./member.vue?vue&type=script&lang=js&"
import style0 from "./member.vue?vue&type=style&index=0&id=48bdc04e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48bdc04e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonListScrollbar: require('/builds/wj/fe/wenjuan-homesite/components/common/list-scrollbar.vue').default,CommonDevModeWrap: require('/builds/wj/fe/wenjuan-homesite/components/common/dev-mode-wrap.vue').default})
