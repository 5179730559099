
import { tools, WwOpenComponent } from '@wenjuan/business'
import { curryFactory, upDatePackageInfo } from '~/utils/tools.js'
import { basicInfo, resetAble } from '@/api/member/account/accountInfo/index.js'
import { memberQuota, memberType, uploadSpace } from '@/api/member/account/unifybuy/index.js'
import { getCooperationList } from '~/api/list/index'

export default {
  name: 'Mine',
  components: {
    WwOpenComponent,
  },
  provide() {
    return {
      member: this,
    }
  },
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  data() {
    const createNav = curryFactory('label', 'path', { isActive: false })
    const navMist = {
      账号信息: createNav('账号信息', '/member'),
      套餐余额: createNav('套餐余额', '/member/unifybuy/'),
      企业认证: createNav('企业认证', '/member/certificate/'),
      钱包: createNav('钱包', '/member/mywallet/'),
      优惠劵: createNav('优惠劵', '/member/mycoupon/'),
      公共模板: createNav('公共模板', '/member/mycollect/'),
      个人模板: createNav('个人模板', '/member/mypersonalcollect/'),
      环保豆: createNav('环保豆', '/rewards/pc/home/'),
      下载: createNav('下载', '/member/mydownloadcenter/'),
      自定义域名: createNav('自定义域名', '/member/custom-domain/'),
      服务号绑定: createNav('服务号绑定', '/member/weixinbook/'),
      邮箱管理: createNav('邮箱管理', '/member/myemail/'),
      API对接: createNav('API对接', '/member/common/authorization/'),
      账号授权: createNav('账号授权', '/member/common/isv/'),
      短信签名: createNav('短信签名', '/member/sms/'),
    }
    return {
      isActive: '账号', // 高亮左侧菜单
      currNavigator: {}, // 左侧菜单对象
      curTab: {}, // 高亮中间选项卡tab
      head: require('@/assets/image/account/head.png'),
      unLoginHead: require('@/assets/logo/header.png'),
      navMist, // 路由集合
      categories: (!PRIVATE_MODE
        ? [
            // 左侧路由集合
            {
              category: '账号',
              route: '/member/',
              icon: 'account',
              src: require('@/assets/image/account/categoryIcon/account.png'),
              navigator: [navMist['账号信息'], navMist['套餐余额'], navMist['企业认证']],
            },
            {
              category: '钱包',
              route: '/member/mywallet/',
              icon: 'wallet',
              src: require('@/assets/image/account/categoryIcon/wallet.png'),
              navigator: [navMist['钱包'], navMist['优惠劵']],
            },
            {
              category: '收藏',
              route: '/member/mycollect/',
              icon: 'collect',
              src: require('@/assets/image/account/categoryIcon/collect.png'),
              navigator: [navMist['公共模板'], navMist['个人模板']],
            },
            // {
            //   category: '环保豆',
            //   route: '/rewards/pc/home/',
            //   icon: 'beans',
            //   src: require('@/assets/image/account/categoryIcon/beans.png'),
            //   navigator: [navMist['环保豆']],
            // },
            {
              category: '下载',
              route: '/member/mydownloadcenter/',
              icon: 'download',
              src: require('@/assets/image/account/categoryIcon/download.png'),
              navigator: [navMist['下载']],
            },
            {
              category: '自定义域名',
              route: '/member/custom-domain/',
              icon: 'account',
              src: require('@/assets/image/account/categoryIcon/domain.png'),
              navigator: [navMist['自定义域名']],
            },
            {
              category: '服务号绑定',
              route: '/member/weixinbook/',
              icon: 'account',
              src: require('@/assets/image/account/categoryIcon/service.png'),
              navigator: [navMist['服务号绑定']],
            },
            {
              category: '邮箱管理',
              route: '/member/myemail/',
              icon: 'account',
              src: require('@/assets/image/account/categoryIcon/mail.png'),
              navigator: [navMist['邮箱管理']],
            },
            {
              category: 'API对接',
              route: '/member/common/authorization/',
              icon: 'account',
              src: require('@/assets/image/account/categoryIcon/api.png'),
              navigator: [navMist['API对接'], navMist['账号授权']],
            },
            {
              category: '短信签名',
              route: '/member/sms/',
              icon: 'account',
              src: require('@/assets/image/account/categoryIcon/sms.png'),
              navigator: [navMist['短信签名']],
            },
          ]
        : [
            // 左侧路由集合
            {
              category: '账号',
              route: '/member/',
              icon: 'account',
              src: require('@/assets/image/account/categoryIcon/account.png'),
              navigator: [navMist['账号信息']],
            },
            {
              category: '收藏',
              route: '/member/mycollect/',
              icon: 'collect',
              src: require('@/assets/image/account/categoryIcon/collect.png'),
              navigator: [navMist['公共模板']],
            },
            {
              category: '下载',
              route: '/member/mydownloadcenter/',
              icon: 'download',
              src: require('@/assets/image/account/categoryIcon/download.png'),
              navigator: [navMist['下载']],
            },
            {
              category: '邮箱管理',
              route: '/member/myemail/',
              icon: 'account',
              src: require('@/assets/image/account/categoryIcon/mail.png'),
              navigator: [navMist['邮箱管理']],
            },
          ]
      ).map((v) => ({ ...v, hasPath: v.navigator.map(({ path }) => path), childPath: v.navigator[0].path })),
      comboType: {
        free: require('@/assets/image/account/accountCombo/free.png'),
        advance_version: require('@/assets/image/account/accountCombo/senior.png'),
        enterprise_version: require('@/assets/image/account/accountCombo/enterprise.png'),
        enterprise_ultimate_version: require('@/assets/image/account/accountCombo/flagship.png'),
      },
      loading: true,
    }
  },
  // fetchOnServer: false, 加上页面渲染较慢
  async fetch() {
    if (this.haslogin == 0) return false
    try {
      const res = await basicInfo()
      this.$store.commit('member/setBasicInfo', res.data)
      await upDatePackageInfo({
        instance: this,
        params: {
          is_get_personal_package_info: 1,
        },
      })
      const res2 = await resetAble()
      this.$store.commit('member/setReset', res2.data.can_account_cancel)
      const res3 = await memberQuota()
      this.$store.commit('member/setMemberBalance', res3.data)
      const res4 = await memberType()
      const res5 = await getCooperationList({
        page_size: 2,
      })
      // 涉及地方不详 暂且这样改
      if (res4.data.space_type == 2 && res4.data.is_admin == 0) {
        // 企业空间非企业主
        this.$store.commit('member/setMemberType', 'corporate')
      } else if (res5.data.total_count) {
        // 协作状态
        this.$store.commit('member/setMemberType', 'cooperation')
      } else {
        this.$store.commit('member/setMemberType', false)
      }
      const res6 = await uploadSpace(1)
      this.$store.commit('member/setUploadSpace', res6.data)
    } catch (error) {
      console.log(error)
    }
  },
  computed: {
    // 是不是登入状态
    isLogin() {
      return this.$store?.state?.loginStatus?.loggedIn == 1
    },
    userPackage() {
      return this.$store.state.member.packageInfo.version
    },
    basicInfo() {
      return this.$store.state.member.basicInfo
    },
    hideScrollBar() {
      return this.$route?.path.includes('/member/mycoupon')
    },
    isPrivateMode() {
      return PRIVATE_MODE
    },
    // todo 当前账号是否为企业微信账号
    isWxWorkUser() {
      return tools.$ENV.defineState().isWAUser === 'isWxwork'
    },
  },
  watch: {
    // 路由变化改变导航索引
    '$route.path': {
      handler() {
        this.acriveRoute()
      },
      // immediate: true,
    },
  },
  mounted() {
    this.initCheckPathItem()
    console.log('测试')
  },
  methods: {
    // 左侧切换菜单
    changeCategory(item) {
      this.isActive = item.category
      this.curTab && (this.curTab.isActive = false)
      this.currNavigator = item
      this.curTab = this.currNavigator?.navigator?.[0]
      this.curTab && (this.curTab.isActive = true)
      this.$router.push(item.route)
    },
    goback() {
      this.$router.push('/list')
    },
    initCheckPathItem() {
      // 未登入状态， 直接去登入页面
      this.loading = true
      if (!this.isLogin) {
        window.location.href = '/register_login'
        return
      }
      this.loading = false
      this.$nextTick(() => {
        this.acriveRoute()
      })
    },
    // 切换卡片
    changeTab(tab) {
      if (tab.isActive) return // 移除重复点击
      this.$router.push(tab.path)
    },
    // 设置当前路由的 导航菜单
    // 改变引导名称 setNavigator({label:'new Name'})
    setNavigator(object) {
      Object.assign(this.curTab, object)
    },
    // 获取当前路由匹配对象
    getRouteMap() {
      return this.categories.find(
        ({ hasPath }) => !!hasPath.find((path) => [path, path + '/'].includes(this.$route.path))
      )
    },
    /**
     * @description : 自动高亮并匹配左侧菜单与tab 的高亮
     * @param {Object} item =》 左侧对应的对象
     */
    acriveRoute(item) {
      const tacth = item || this.getRouteMap()
      if (tacth) {
        if (this.currNavigator !== tacth) {
          this.currNavigator = tacth
          this.isActive = tacth.category
        }
        const { path } = this.$route
        this.currNavigator.childPath = path
        this.curTab && (this.curTab.isActive = false) // 移除
        this.curTab = this.currNavigator.navigator.find(({ path: _path }) => [_path, _path + '/'].includes(path))
        this.curTab && (this.curTab.isActive = true) // 添加
        // 跳转逻辑 如果相同不在做定向跳转
        if (this.$route.path !== this.curTab.path) this.$router.push(this.curTab.path)
        this.scrollToTop()
      } else {
        // 不存在 调转到列表页面
        this.$router.push('/member') // 不存在路由, 则去列表页面
        console.log('路由地址错误，即将返回列表页面')
      }
    },
    // 滚动到顶部
    scrollToTop() {
      if (window.document) {
        const scrollMain = document.querySelector('.main')
        if (scrollMain && scrollMain.scrollTop !== 0) {
          scrollMain.scrollTop = 0
        }
      }
    },
  },
}
