/**
 * @description : 微应用使用的中间件函数， 方便日后统一 修改参数
 */
import { tools } from '@wenjuan/business'
export default function ({ store }) {
  tools.$ENV.defineState(($store) => {
    const {
      mediaType: { isDingTalk, isFeishu, isWxwork },
      member: { packageInfo },
    } = ($store || store)?.state || {}
    const { isWAUser, isFreeTry = false, version } = packageInfo || {}
    // 目前 微应用 isWAUser 的返回类型
    const mapList = {
      isWxwork: 'isWxwork',
      isDingTalk: 'isDingTalk',
      business_wx: 'isWxwork',
      ding_talk: 'isDingTalk',
      feishu: 'isFeishu',
      isFeishu: 'isFeishu',
      default: '',
    }
    return {
      isFreeTry, // 试用版套餐
      isWAUser: mapList[isWAUser] || mapList.default, // 微应用账户
      isMicroEnv: isDingTalk || isFeishu || isWxwork, // 是不是微微应用环境
      isDingTalk, // 钉钉
      isFeishu, // 飞书
      isWxwork, // 企业微信
      curMicro: isDingTalk ? 'isDingTalk' : isFeishu ? 'isFeishu' : isWxwork ? 'isWxwork' : 'Mozilla', // 当前微笑应用环境
      version,
    }
  })
}
