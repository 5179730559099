/**
 * vant已处理成支持按需引入，不能全局引入
 *
 * https://vant-contrib.gitee.io/vant/v2/#/zh-CN/quickstart
 */
import Vue from 'vue'
import { Button, Tab, Tabs, Swipe, SwipeItem, Toast } from 'vant'

Vue.use(Button)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Toast)
