/**
 * @description 高亮导航匹配规则对象，需要的请自行匹配
 */
export default [
  {
    name: '样本收集',
    path: '/collect_post',
    active: 'collectPost',
  },
]
