import { render, staticRenderFns } from "./new-lib-search.vue?vue&type=template&id=6a956936&scoped=true&"
import script from "./new-lib-search.vue?vue&type=script&lang=js&"
export * from "./new-lib-search.vue?vue&type=script&lang=js&"
import style0 from "./new-lib-search.vue?vue&type=style&index=0&id=6a956936&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a956936",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonButton: require('/builds/wj/fe/wenjuan-homesite/components/common/button.vue').default,CommonImage: require('/builds/wj/fe/wenjuan-homesite/components/common/image.vue').default})
