import api from '@/api/base'

// 获取用户基本信息
export function basicInfo() {
  return api.get('/api/member/basic_info/')
}

// 修改昵称
export function changeNickname(params) {
  return api.post('/api/member/update_nickname/', params)
}

// 更新密码
export function updatePwd(params) {
  return api.post('/api/member/password/update/', params)
}

// 重置密码
export function resetPwd(params) {
  return api.post('/api/member/password/reset/', params)
}

// 邮箱换绑验证码校验
export function emailCheckVcode(params) {
  return api.post('/api/member/email/vcode_validate/', params)
}

// 绑定邮箱获取验证码
export function generateVcode(params) {
  return api.post('/api/member/email/vcode/send/', params)
}

// 更改绑定邮箱
export function changeBindEmail(data) {
  return api.post('/api/member/email/change/', data)
}

// 解绑邮箱
export function unbindEmail(params) {
  return api.post('/api/member/email/unbind/', params)
}

// 获取验证码
export function getVcode(data) {
  return api.post('/api/member/vcode/generate/', data)
}

// 校验验证码
export function checkVcode(data) {
  return api.post('/api/member/vcode/validate/', data)
}

// 更换手机号
export function updateMobile(data) {
  return api.post('/api/member/mobile/update/', data)
}

// 绑定手机号
export function bindMobile(data) {
  return api.post('/api/member/mobile/bind/', data)
}

// 校验是否主动发送短信
export function checkMsg(params) {
  return api.post('/api/sms/upload/validate/', params)
}

// 判断当前账号是否可以注销
export function resetAble() {
  return api.get('/member/can_member_cancel/')
}

// 注销账号
export function logout() {
  return api.post('/member/tmp/close_account/')
}

// 绑定微信
export function bindWechat() {
  return api.post('/auth/check_bind_weixin/')
}

// 获取用户绑定url
export function bindUrl() {
  return api.get('/member/wechat_binding/url/')
}
// 获取微信绑定二维码
export function bindImg(params) {
  return api.get(`images/get_matrix_img?survey_url=${params.survey_url}&box_size=5`, { responseType: 'blob' })
}

// 绑定微博
export function bindSina(params) {
  return api.get(`/bind/sina?furl=${params.furl}`)
}

// 绑定QQ
export function bindQQ(params) {
  return api.get(`/bind/qq/?furl=${params.furl}`)
}

// 获取项目总数
export function objectList() {
  return api.get('/api/project/list/')
}

// 第三方解绑
export function unbindSocial(params) {
  return api.post(`/auth/unbind_account/?source=${params.source}`)
}
