/**
 * @author :luowei
 * @description: 扩展地址
 * @date 2023-9-01
 * @deprecated: change this function
 * @param {content} param
 *
 * @return void(0)
 */
export default function ({ store }) {
  try {
    const originCommitTask = store.commit
    store.commit = function (dispatch, data) {
      if (
        Object.prototype.toString.call(data).slice(8, -1) === 'Object' ||
        Array.isArray(data) ||
        typeof data === 'function'
      ) {
        data.sourceFromRoutePath = this.$router?.currentRoute?.path
      }
      originCommitTask(dispatch, data)
    }
  } catch (e) {
    throw new Error('store.commit error,and path ：', store?.$router?.currentRoute?.path)
  }
}
