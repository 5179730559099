
import { mapState, mapMutations } from 'vuex'
import { getSceneType } from '~/api/library-center/common.js'

export default {
  name: 'ScenceSingle',
  data() {
    return {
      transparent: false,
    }
  },
  fetchOnServer: false,
  fetch() {
    this.$store.commit('setHightLightHeader', {
      transparent: true,
    })
  },
  computed: {
    ...mapState(['loginStatus', 'headerActive', 'isMobile', 'mediaType']),
  },
  mounted() {
    getSceneType(this)
    // 针对场景页的静态化构建，需要每次刷新页面，重新执行cookie和ua的获取
    this.$store.commit('cookie/parse', document.cookie)
    this.judgeUA()
  },
  methods: {
    ...mapMutations(['setHightLightHeader']),
    judgeUA() {
      const ua = window.navigator.userAgent
      const isMobile = /(iPad|iPhone|iPod|Opera Mini|Android.*Mobile|NetFront|PSP|BlackBerry|Windows Phone)/gi.test(ua)
      const obj = {
        isEdge: ua.includes('Edge'),
        isFF: ua.includes('Firefox'),
        isOpera: ua.includes('Opera'),
        isBB: ua.includes('BlackBerry'),
        isChrome: ua.includes('Chrome'),
        isMaxthon: ua.includes('Maxthon'),
        isFeishu: ua.includes('Lark/'),
        isIos: /(iPhone|iPad|iPod|iOS)/i.test(ua),
        isSafari: ua.includes('Safari') && !ua.includes('Chrome'),
        isMobile,
        isPC: !isMobile,
        isWeixin: ua.toLowerCase().match(/MicroMessenger/i) == 'micromessenger',
        isAlipay: ua.toLowerCase().match(/Alipay/i) == 'alipay',
        isWeibo: ua.toLowerCase().match(/WeiBo/i) == 'weibo',
        isWxwork: ua.toLowerCase().match(/wxwork/i) == 'wxwork',
        isQQ: ua.toLowerCase().match(/QQ/i) == 'qq',
        isAndroidApp: ua.toLowerCase() == 'android_app/1.0.0',
        isAndroid:
          (ua.toLowerCase().match(/android/i) == 'android' || ua.toLowerCase().match(/adr/i) == 'adr') &&
          ua.toLowerCase() != 'android_app/1.0.0',
        isCloudPay: ua.includes('unionpay'),
        // ios android 客户端
        isNative:
          ua.toLowerCase().match(/android_app\//i) == 'android_app/' ||
          ua.toLowerCase().match(/wenjuan\//i) == 'wenjuan/',
        // 判断是否鸿蒙fa卡片
        isHarmony: ua.toLowerCase().match(/harmony_wenjuanwrap/i) == 'harmony_wenjuanwrap',
        // 是否钉钉应用打开
        isDingTalk: ua.toLowerCase().match(/DingTalk/i) == 'dingtalk',
        // 是否腾讯会议
        isTencentWemeet: ua.toLowerCase().match(/tencent_wemeet/i) == 'tencent_wemeet',
        isBaiying: ua.toLowerCase().match(/Baiying/i) == 'baiying',
        isMac: /macintosh|mac os x/i.test(ua),
        isBaidu: ua.toLowerCase().includes('baiduboxapp'),
        isPad: ua.toLowerCase().includes('ipad'),
      }

      this.$store.commit('setMediaType', {
        userAgent: ua,
        ...obj,
      })
    },
  },
}
