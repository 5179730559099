
import { MobileLoginPopup } from '@wenjuan/business'
export default {
  description: '调式框架',
  name: 'DevModeWrap',
  components: {
    PackageDialog: () => import('~/components/common/packageDialog.vue'),
    SampleDialog: () => import('~/components/common/sampleDialog.vue'),
  },
  computed: {
    loginStatus() {
      return this.$store.state.loginStatus
    },
  },
  methods: {
    setClose() {
      this.$store.commit('setLoginStatus', {
        mobileVisible: false,
      })
    },
  },
  render() {
    return (
      <div class="finaly-wrap">
        <PackageDialog data-desc="购买弹窗" />
        <CommonRegister data-desc="登入注册页面" />
        <MobileLoginPopup
          visible={this.loginStatus.mobileVisible}
          nextUrl={this.loginStatus.nextUrl}
          redirectUrl={this.loginStatus.mobileRedirectUrl}
          miniAppLogin={this.loginStatus.miniAppLogin}
          onClose={this.setClose}
        />
        <SampleDialog data-desc="样本服务弹窗" />
      </div>
    )
  },
}
