// 从server或client获取user-agent字段，并更新store的state
export default function ({ req, store }) {
  let reqUserAgent = ''
  // 需要兼容static构建
  if (process.server && !process.static) {
    const { headers } = req
    reqUserAgent = headers['user-agent']
  }
  if (process.client) {
    reqUserAgent = window.navigator.userAgent
  }
  if (reqUserAgent === undefined) {
    reqUserAgent = ""
  }

  const mediaType = ($store) => {
    const ua = reqUserAgent
    const isMobile = /(iPad|iPhone|iPod|Opera Mini|Android.*Mobile|NetFront|PSP|BlackBerry|Windows Phone)/gi.test(ua)
    const obj = {
      isEdge: ua.includes('Edge'),
      isFF: ua.includes('Firefox'),
      isOpera: ua.includes('Opera'),
      isBB: ua.includes('BlackBerry'),
      isChrome: ua.includes('Chrome'),
      isMaxthon: ua.includes('Maxthon'),
      isFeishu: ua.includes('Lark/'),
      isIos: /(iPhone|iPad|iPod|iOS)/i.test(ua),
      isSafari: ua.includes('Safari') && !ua.includes('Chrome'),
      isMobile,
      isPC: !isMobile,
      isWeixin: ua.toLowerCase().match(/MicroMessenger/i) == 'micromessenger',
      isAlipay: ua.toLowerCase().match(/Alipay/i) == 'alipay',
      isWeibo: ua.toLowerCase().match(/WeiBo/i) == 'weibo',
      isWxwork: ua.toLowerCase().match(/wxwork/i) == 'wxwork',
      isQQ: ua.toLowerCase().match(/QQ/i) == 'qq',
      isAndroidApp: ua.toLowerCase() == 'android_app/1.0.0',
      isAndroid:
        (ua.toLowerCase().match(/android/i) == 'android' || ua.toLowerCase().match(/adr/i) == 'adr') &&
        ua.toLowerCase() != 'android_app/1.0.0',
      isCloudPay: ua.includes('unionpay'),
      // ios android 客户端
      isNative:
        ua.toLowerCase().match(/android_app\//i) == 'android_app/' ||
        ua.toLowerCase().match(/wenjuan\//i) == 'wenjuan/',
      // 判断是否鸿蒙fa卡片
      isHarmony: ua.toLowerCase().match(/harmony_wenjuanwrap/i) == 'harmony_wenjuanwrap',
      // 是否钉钉应用打开
      isDingTalk: ua.toLowerCase().match(/DingTalk/i) == 'dingtalk',
      // 是否腾讯会议
      isTencentWemeet: ua.toLowerCase().match(/tencent_wemeet/i) == 'tencent_wemeet',
      isBaiying: ua.toLowerCase().match(/Baiying/i) == 'baiying',
      isMac: /macintosh|mac os x/i.test(ua),
      isBaidu: ua.toLowerCase().includes('baiduboxapp'),
      isPad: ua.toLowerCase().includes('ipad'),
    }

    $store.commit('setMediaType', {
      userAgent: reqUserAgent,
      ...obj,
    })
  }
  mediaType(store)
}
