/**
 *  例子：
 *  {
*   path: '/lib',
    name: '模板中心',
    to: (this: instanceVue) => Object<{next: String,...any<其他参数>}>  | Object<next: String,...any<其他参数> | undefined   函数或者对象    匹配上对象，但是没有to 则默认回到当前页 
    dialogTo:  (this: instanceVue) => String   | String   | undefined 匹配上对象，但是没有dialogTo 则默认回到当前页 
 *  }
 * 
 */
export default [
  {
    path: '/lib',
    name: '模板中心',
  },
]
