// 存放axios实例api请求相关的工具函数

import md5 from 'blueimp-md5'
import { tools } from "@wenjuan/business";
import { SIGNATURECONF_OLD, SIGNATURECONF_PWA, SIGNATURECONF } from './constants'



// 旧版：签名请求数据
export function signatureFunc_old(conf) {
  const { method } = conf
  const { APP_KEY, SECRET, SITE } = tools.usePWASignature?.isUsedPWASignature(conf.url) ? SIGNATURECONF_PWA : SIGNATURECONF_OLD

  // 添加校验字段，规则 https://www.wenjuan.com/open/devdocument_v3_3/?chapter=3_2#API-signature-rule
  const checkField = {
    appkey: APP_KEY,
    web_site: SITE,
    timestamp: new Date().getTime(),
  }

  const genSign = (raw = {}) => {
    Object.assign(raw, checkField)
    const keyList = Object.keys(raw).sort()
    const valueList = []
    keyList.forEach((key) => {
      if (key !== 'secret') valueList.push(raw[key])
    })
    raw.signature = md5(valueList.join('') + SECRET)
    return raw
  }
  if (method === 'get') {
    conf.params = genSign(conf.params)
  } else {
    conf.data = genSign(conf.data)
  }
  return conf
}

// 新版：签名请求数据
export function signatureFunc_new(conf) {
  const { params, data } = conf
  const { APP_KEY, SECRET } = SIGNATURECONF

  // 将对象的key升序，取值的字符串拼接
  const sortVal = (v) => {
    v = !v ? '' : v
    let s = ''
    for (const key of Object.keys(v).sort()) {
      s += v[key]
    }
    return s
  }

  // json序列化，处理了fasly值转成空字符串
  const marshal = (v) => {
    // 并不是完全的序列化，必须v是个对象结构，才走序列化，否则就是空字符串
    if (!v || typeof v !== 'object') {
      return ''
    }
    return JSON.stringify(v)
  }

  conf.headers.Authorization = `wj ${APP_KEY}:${md5(marshal(data) + sortVal(params) + SECRET)}`
  return conf
}

/**
 * 根据环境变量，动态返回SSR/CSR的接口请求地址，SSR会用内网地址请求，加速接口速度
 * @param {string} proxyLocalDomain 用于本地浏览器请求反向代理的地址，可选
 * @returns
 */
export function genBaseURL(proxyLocalDomain) {
  if (process.server) {
    return process.env.NUXT_INTER_URL || process.env.NUXT_BASE_URL
  } else {
    return proxyLocalDomain || process.env.NUXT_BASE_URL
  }
}
