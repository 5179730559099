import api from '../base'

/**
 * @method 获取场景列表
 */
export function httpGetSceneList(param) {
    return api.get("/edit/api/scene_list/", param);
}

/**
 * @method 新建项目页面获取验证信息
 */
export function httpGetVerifyInfo(param) {
    return api.get("/edit/api/verify/", param);
}

/**
 * @method 空白创建项目
 */
export function httpPostCreateProject(param) {
    param.unInterceptors = true;
    return api.post("/edit/api/create/?", param);
}
