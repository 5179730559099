
import { mapState, mapMutations } from 'vuex'
import { removeMeta, useMemo } from '~/utils/tools.js'
import NewLibSearch from '~/components/library-center/new-lib-search.vue'
import LibAiCreate from '~/components/library-center/lib-ai-create.vue'
import { libraryCountDetail } from '~/api/library-center/common.js'
const [memoWidth, memoPage, memoScroll] = [useMemo(), useMemo(), useMemo()]

export default {
  name: 'LibMain',
  components: {
    LibAiCreate,
    NewLibSearch,
    LibComposePreview: () => import('~/components/library-center/lib-compose-preview.vue'),
  },
  fetchOnServer: false,
  data() {
    return {
      countDetail: {},
      searchType: 2,
      setConf: null,
      showSearch: false,
    }
  },
  async fetch() {
    try {
      const { data } = await libraryCountDetail()
      const { all_lib_num = 0, official_lib_num = 0, recent_lib_num = 0 } = data || {}
      const { numberFormat } = this
      this.countDetail = [
        {
          src: 'all-lib-num',
          label: `免费模板${numberFormat(all_lib_num)}`,
        },
        {
          src: 'official-lib-num',
          label: `官方模板${numberFormat(official_lib_num)}`,
        },
        {
          src: 'recent-lib-num',
          label: `近期更新${numberFormat(recent_lib_num)}`,
        },
      ]
    } catch (e) {
      console.log(e)
    }
  },
  head: {
    title: '问卷满意度调查分析报告-市场调研表格模板-问卷网',
    meta: [
      {
        hid: 'keywords',
        name: 'keywords',
        content: '问卷,调查模板,问卷模板,问卷调查,调查问卷,调查分析报告,问卷调查表,满意度调查,市场调查问卷',
      },
      {
        hid: 'description',
        name: 'description',
        content:
          '问卷网(www.wenjuan.com)拥有内容丰富的问卷模板库，由专业研究员设计和会员共享的海量市场调查问卷模板、调查问卷格式，让你轻松制作各类调查问卷、调研分析报告，进行问卷调查表设计等',
      },
    ],
  },
  computed: {
    ...mapState(['loginStatus', 'headerActive', 'mediaType', 'loading']),
  },
  mounted() {
    this.setHightLightHeader({
      active: 'lib',
    })
    removeMeta('applicable-device')
    this.$watch('$store.state.innerWidth', (cval) => this.proxyClientWidth(cval), { immediate: true })
  },
  methods: {
    ...mapMutations(['setHightLightHeader']),
    numberFormat(value) {
      if (Number(value) > 10000) return Math.round(value / 10000) + '万'
      return value
    },
    proxyClientWidth(width) {
      const critical = width > 1280
      memoWidth(critical, () => {
        this.setConf = ({ list }) => {
          return this.showSearch ? list.slice(0, critical ? list.length : -2) : list
        }
      })
      const type = width > 1150 ? 2 : 3
      memoPage(type, () => (this.searchType = type))
    },
    scroll(top) {
      memoScroll(top > 310, () => {
        this.showSearch = top > 310
      })
    },
  },
}
