import Vue from 'vue'
import track from '@wenjuan/track-sdk-javascript'
import { set as setCookie, get as getCookie, del as delCookie } from '~/utils/cookie.js'
import { distinguishUserAgentUtils } from '~/utils/tools'

// console.log('third_part_stat js loaded.')
Vue.use(track, {
  // 公共配置信息
  hashtag: false,
  debug: false,
})

// 登录用户传给gio对应的用户id
gio_login_status()

// 设置页面来源页Cookie
setRefererCookie()
setupRegisterOrLoginTrack() // 默认调用一次埋点方法

// 用户创建项目成功手动推送事件
function gio_new_project_create(current_scene_type) {
  delCookie('create_project')
}

// 退出登录 清除登录用户 ID
function gio_logout() {
  sessionStorage.removeItem('folderId')
  sessionStorage.removeItem('list_pagenum')
}

// 登录用户传给gio对应的用户id
function gio_login_status() {
  sessionStorage.removeItem('list_pagenum')
  if (getCookie && getCookie('login_status') == '1') {
    delCookie('login_status')
  }
}

// 将cookie字符串转成对象格式
function analyzeCookie(totalCookie) {
  try {
    const cookieArr = totalCookie.split(';')
    const cookieObject = {}
    for (let i = 0; i < cookieArr.length; i++) {
      const cookieItem = cookieArr[i].split('=')
      if (cookieItem[0] || cookieItem[0] === 0) cookieObject[cookieItem[0].trim()] = cookieItem[1].trim()
    }
    return cookieObject
  } catch (e) {
    console.log(e)
  }
}

// 删除冗余的cookie，str是要删除的cookie字符串（会做自动匹配） iskeepOne是否保留重复中的一条
function deleteRedundancyCookieByRegexp(str, isKeepOne) {
  const totalCookieObj = analyzeCookie(document.cookie)
  const re = new RegExp(`\\w*${str}\\w*`, 'i') // 正则匹配前后字符
  let firstMatch = ''
  for (const k in totalCookieObj) {
    if (re.test(k)) {
      if (!firstMatch && isKeepOne) {
        // 保留第一条匹配项
        firstMatch = k
      } else {
        delCookie(k)
      }
    }
  }
}

// 删除冗余的 *_gr_session_id_* 标识的cookie
deleteRedundancyCookieByRegexp('_gr_session_id_')

// 添加页面来源cookie
function setRefererCookie() {
  const domain = (window.document.referrer || '').replace(/(^https{0,1}:\/\/)|(\/\S*$)/g, '') || ''
  const host = window.location.hostname
  if (domain.includes(host)) return
  const cachedReferer = getCookie('origin_referer') || domain || 'default'
  const cachedPath = getCookie('origin_path') || window.location.pathname
  setCookie('origin_referer', cachedReferer, 7) || setCookie('origin_path', cachedPath, 7)
}

// cookie字符串转json
function cookieStrToJson(jsonStr) {
  let result = ''
  try {
    result = jsonStr ? JSON.parse(JSON.parse(jsonStr.replace(/\\054/g, ','))) : false
  } catch (error) {
    console.log('埋点数据格式有误')
    result = false
  }
  return result
}

// sdk2期埋点: 根据cookie is_new_regmember 或 track_login_payload 触发埋点
export function setupRegisterOrLoginTrack() {
  if (typeof window !== 'undefined') {
    try {
      // 注册埋点
      if (getCookie('is_new_regmember') === '1' && getCookie('track_register_payload')) {
        const trackRegPayload = cookieStrToJson(getCookie('track_register_payload'))
        delCookie('track_register_payload')
        delCookie('is_new_regmember')
        delCookie('utm_source')
        // delCookie('origin_referer');
        if (trackRegPayload) {
          window.zytrack && window.zytrack('custom', 'register', trackRegPayload)
        }
      }
      // 登录埋点
      let trackLoginPayload = cookieStrToJson(getCookie('track_login_payload'))
      if (trackLoginPayload) {
        delCookie('track_login_payload')
        delCookie('utm_source')
        // delCookie('origin_referer');
        if (Object.keys(trackLoginPayload).length > 1) {
          window.zytrack && window.zytrack('custom', 'login', trackLoginPayload)
        }
        trackLoginPayload = null
      }
      // 创建项目埋点
      let projectCreatePayload = cookieStrToJson(getCookie('project_create_payload'))
      if (projectCreatePayload) {
        delCookie('project_create_payload')
        if (Object.keys(projectCreatePayload).length > 1) {
          window.zytrack && window.zytrack('custom', 'project_create', projectCreatePayload)
        }
        projectCreatePayload = null
      }
      // 发布项目埋点
      let projectPublishPayload = cookieStrToJson(getCookie('project_publish_payload'))
      if (projectPublishPayload) {
        delCookie('project_publish_payload')
        if (Object.keys(projectPublishPayload).length > 1) {
          window.zytrack && window.zytrack('custom', 'project_publish', projectPublishPayload)
        }
        projectPublishPayload = null
      }
      // 模板搜索埋点
      let projectSearchPayload = cookieStrToJson(getCookie('template_search'))
      if (projectSearchPayload) {
        delCookie('template_search')
        if (Object.keys(projectSearchPayload).length > 1) {
          window.zytrack && window.zytrack('custom', 'template_search', projectSearchPayload)
        }
        projectSearchPayload = null
      }
    } catch (error) {
      console.log('埋点出错')
      console.log(error)
    }
  }
}

window.gio_new_project_create = gio_new_project_create
window.gio_logout = gio_logout
