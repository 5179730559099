// 前端错误监控集成
// 区分了浏览器端和node端环境
// 文档：https://wiki.idiaoyan.cn/pages/viewpage.action?pageId=49610871
//
// 由于项目使用了webpack和uglify等，所以为了让异常/错误定位到具体的执行栈，需要上传source-map信息到sentry
// 上传source-map等操作使用sentry-webpack-plugin，在nuxt.confing.js的build位置

import { currentEnv } from '../utils/build-about/env'

let setupFunc = null
const wjTags = {}

if (process.server) {
  setupFunc = require('@wenjuan/tracker-node').setup
  wjTags.service_name = 'wenjuan-homesite-ssr'
} else {
  setupFunc = require('@wenjuan/tracker-browser').setup
  wjTags.service_name = 'wenjuan-homesite-csr'
}
const env = currentEnv()[0]

const options = {
  logErrors: true,
  dsn: 'https://7a7a5ebf0fe84c9a9a2ee0bf0a6c847a@sentry.idiaoyan.cn/45',
  environment: currentEnv()[0],
  release: process.env.COMMIT_ID,
  wjTags,
  tracesSampleRate: env == 'prod' ? 0.2 : 1.0,
}

// 初始化sentry，会自动上报异常/错误，和用户监控等
if (!process.dev) {
  setupFunc(options)
}
