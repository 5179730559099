// 存储与nuxt内部api相关的模块

let nuxtContext = null

export function setNuxtCtx(ctx) {
  nuxtContext = ctx
}

export function nuxtCtx() {
  return nuxtContext
}
