// 监听全局变化
import { getClientWidth, curryDalay } from '../utils/tools'
export default function ({ store }) {
  const saveWidth = function () {
    curryDalay(getClientWidth, 30, (data) => {
      store.commit('getClientWidth', data)
    })()
  }
  saveWidth()
  window.addEventListener('resize', saveWidth)
  window.addEventListener('unload', () => {
    window.removeEventListener('resize', saveWidth)
  })
}
