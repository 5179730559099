
export default {
  name: 'CommonButton',
  props: {
    // 使用原始样式
    originClass: {
      type: Boolean,
      default: false,
    },
    //  自定义样式
    className: {
      type: String,
      default: '',
    },
    //  按钮类型，自动改变样式
    type: {
      type: String,
      default: 'text',
    },
    // 文字
    text: {
      type: String,
      default: '',
    },
    // 边框圆角
    radius: {
      type: String,
      default: '4px',
    },
    // 宽
    width: {
      type: String,
      default: '',
    },
    // 高度
    height: {
      type: String,
      default: '',
    },
    // 字体大小
    fontSize: {
      type: String,
      default: '',
    },
    // 链接地址
    href: {
      type: String,
      default: '',
    },
    padding: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedStyle() {
      const { padding, width, height, fontSize, color } = this
      return Object.filter({ padding, width, height, fontSize, color }, (value) => value || value === 0)
    },
  },
  methods: {
    click() {
      if (this.href) return
      this.$emit('click')
    },
  },
}
