
import { mapState, mapMutations } from 'vuex'
import { getSceneType } from '~/api/library-center/common.js'
export default {
  data() {
    return {
      fixed: false,
    }
  },
  computed: {
    ...mapState(['isMobile', 'loginStatus', 'headerActive', 'footerInfo', 'mediaType']),
  },
  created() {
    const pathUrl = this.$route.path || ''
    /* eslint-disable */
    // 底部组件（人工客服）引入导致小程序找不到域名问题
    if (pathUrl.indexOf('/register/protocol') === -1 && pathUrl.indexOf('/about/ystk') === -1) {
      this.$store.commit('setFooterInfo', { show: true })
    }
    this.compatiabelMeb()
  },
  mounted() {
    getSceneType(this)
    this.isInIframe()
  },
  methods: {
    ...mapMutations(['setFooterInfo', 'setHightLightHeader']),
    // 如果M站嵌入collect_post页面，需要隐藏头部、底部、头图和悬浮的邀请得券按钮
    compatiabelMeb() {
      const { path, query } = this.$route
      const { from } = query
      const shouldCompatiabelMeb = path.startsWith('/collect_post') && this.isMobile && from === 'mactivity'
      if (shouldCompatiabelMeb) {
        this.setFooterInfo({ show: false })
        this.setHightLightHeader({ show: false })
      }
    },
    // 移除页眉
    isInIframe() {
      const {
        $route: {
          query: { isInIframe },
        },
      } = this
      if (isInIframe === 'true') {
        this.$store.commit('setHightLightHeader', { show: false })
        this.$store.commit('setFooterInfo', { show: false })

        this.$nextTick(() => {
          if (window.parent) {
            setTimeout(() => {
              const dom = document.querySelector('.nuxt-content')
              window.parent.postMessage(
                {
                  source: 'homite',
                  path: 'collect-post',
                  action: 'set-height',
                  payload: {
                    height: dom.offsetHeight,
                  },
                },
                '*'
              )
            }, 500)
          }
        })
      }
    },
  },
}
