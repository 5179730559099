
import Vue, { ref, reactive, watch, toRef, onMounted } from 'vue'
import { NewSearch, tools } from '@wenjuan/business'
import { debounce, removeEmpty } from '~/utils/tools'
import { getLibraryList, searchLazyAssociate } from '~/api/library-center/common.js'
const {
  useHooks: { useRef },
} = tools
export default {
  name: 'NewLibSearch',
  components: {
    NewSearch,
  },
  model: {
    event: 'myInput',
  },
  props: {
    // 1: 大搜索框 2：小搜索框,3:mini 搜索框
    type: {
      type: Number,
      default: 1,
    },
    customSearch: {
      type: Function,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
  },
  setup(props, { expose, emit }) {
    const params = reactive({
      keyword: '',
      scene: 0,
      page_size: 4,
      page: 1,
      official: true,
    })
    const { useInstance, useRefs } = Vue
    const recommendList = ref([])
    const reNumber = ref(0)
    const searchList = ref([]) // 模糊查询匹配字段
    const hoverStatus = ref(false) // 面板是不是手动控制panel的展开一关闭
    const instance = useInstance()
    const type = toRef(props, 'type')
    const loading = ref(false)
    const refs = useRefs()
    const libDetailDialog = reactive({
      value: {
        show: false,
        libId: '',
        libIdList: [],
      },
    })

    const input = debounce(async function (keyword) {
      params.keyword = keyword.trim()
      loading.value = true
      try {
        const { data: recommendData } = await getLibraryList(params)
        const { lib_list, total } = recommendData
        recommendList.value = lib_list
        const splitNumber = type.value === 1 ? 4 : 2
        reNumber.value = total > splitNumber ? total - splitNumber : 0
        const { data } = await searchLazyAssociate(keyword)
        const icon = {
          1: 'dark-search',
          2: 'jing-flag',
          3: 'fangxing-2',
        }

        searchList.value = (data || []).map(({ name: title, domain_suffix: domainSuffix, word_type: wordType }) => ({
          title,
          src: `${instance.$cdnhome}common/${icon[wordType]}.svg`,
          href:
            wordType === 1
              ? `/lib/search?keyword=${title}`
              : wordType === 2
              ? `/lib/tags/${title}/`
              : `/topic/${domainSuffix}`,
        }))
      } catch (e) {
        console.log(e)
      } finally {
        loading.value = false
      }
    }, 300)
    const clickFuzzyMatch = (item) => {
      instance.$store.commit('setLoading', true)
      params.keyword = item.title
      instance.$href(item.href)
    }
    const clickPanel = (ite) => {
      params.keyword = ite
      onSearch()
    }
    // 场景勾选
    const selectChange = (value) => {
      params.scene = value
    }
    // 点击加载
    const clickImport = (instance) => {
      hoverStatus.value = true
    }
    const clickCard = ({ oid }) => {
      instance.$store.commit('setLoading', true)
      instance.$href(`/lib_detail_full/${oid}/`)
    }
    const onSearch = async () => {
      instance.$store.commit('setLoading', true)
      const { keyword, scene } = params
      const _params = {
        keyword: keyword || refs?.NewSearch?.defineKeyword || '',
      }
      if (_params.keyword) {
        if (scene) _params.scene = scene
        if (props.customSearch) {
          await this.customSearch(params)
        } else {
          instance.$href('/lib/search', _params)
        }
      } else {
        instance.$store.commit('setLoading', false)
      }
    }
    const clickMore = () => {
      instance.$href('/lib/recommend/', { keyword: params.keyword })
    }

    onMounted(() => {
      libDetailDialog.value = useRef('libDetailDialog', () => ({
        show: false,
        libId: '',
        libIdList: [],
      })).value
    })
    watch(
      () => props.value,
      (cval) => {
        params.keyword = cval
      },
      {
        immediate: true,
      }
    )
    expose({
      clickFuzzyMatch,
      input,
      clickPanel,
      selectChange,
      clickImport,
      clickCard,
      onSearch,
      clickMore,
    })
    return {
      params,
      reNumber,
      recommendList,
      searchList,
      hoverStatus,
      loading,
      libDetailDialog,
    }
  },
}
