import api, { $get } from '../base'
import { signatureFunc_new } from '../helper'

/**
 * 检查成员权限
 * @param {{
 *  permission_type: '1' | '2' | '3';
 *  project_id: string;
 * }} data permission_type： 1表示编辑页检查，2表示发布页检查，3表示报表页检查
 * @returns
 */
export function checkPermission(data) {
  return api.post('/enterprise/api/check_permission/', data)
}

/**
 * 获取项目答题数量
 * @param {{
 *  proj_id: string;
 *  scene_type: string;
 * }} data
 */
export function projectAnswersCount(projectId, sceneType) {
  return api.post('/edit/ajax/get_rspd_count/', {
    proj_id: projectId,
    scene_type: sceneType,
    __FORMDATA__: true,
  })
}

/**
 * 复制项目
 * @param {{
 *  proj_id: string;
 *  func_id: string;
 *  proj_title: string;
 * }} data
 * @returns
 */
export function copyProject(data) {
  return api.post(
    '/edit/ajax/survey_copy/',
    {
      ...data,
      __FORMDATA__: true,
    },
    {
      // 兼容2000题复制请求时间过长，复制请求超时时间改为 10s
      timeout: 10000,
    }
  )
}

/**
 * 根据fission_id获取项目名称
 *
 * copyProjectFromLink需要拿到title字段
 * @param {string} pid fission_id or access_copy
 * @returns
 */
export function projectTitleByFission(pid) {
  return api.get('api/edit/internal/fission_scene/get_project_title/', {
    params: {
      fission_id: pid,
    },
    __SIGNATURE__: signatureFunc_new,
  })
}

/**
 * 从答题分享来的链接复制项目
 * @param {'copy_assess'|'fission'} type
 * @param {{
 *  proj_id: string;
 *  proj_title: string;
 * }} data
 * @returns
 */
export function copyProjectFromLink(type, copy_attachment, data) {
  const url = copy_attachment
    ? `/edit/ajax/survey_copy/?${type}=1&copy_attachment=1`
    : `/edit/ajax/survey_copy/?${type}=1`
  return api.post(url, {
    ...data,
    __FORMDATA__: true,
  })
}

/**
 * 轮询接口，轮询获取复制异步任务是否完成
 * @param {*}
 */
export function httpGetAsyncCopyStatus(params) {
  const pid = params.pid
  return api.get(`/edit/api/get_copy_project_status/${pid}/`)
}

/**
 * 复制飞行棋项目
 * @param {string} pid
 * @param {{
 *  title: string;
 *  target_user_phone?: string;
 *  folder_id?: string;
 * }} data
 * @returns
 */
export function copyFlyingChessProject(pid, data) {
  return api.post(`/flying_chess/api/projects/copy/${pid}`, {
    ...data,
    __FORMDATA__: true,
  })
}

/**
 * 复制项目给他人
 * @param {string} pid
 * @param {{
 *   member_to: string;
 * }} data
 * @returns
 */
export function copyProject2Others(pid, data) {
  return api.post(`/edit/ajax/survey_copy/request/${pid}/`, {
    ...data,
    __FORMDATA__: true,
  })
}

/**
 * 获取复制给他人的链接
 */
export function getShareCopyLink(params) {
  return api.get('/edit/api/project/share_copy/', { params })
}

/**
 * 高级版功能信息
 * @param {string} pid
 * @param {{
 *  plugin_name: string;
 * }} params
 * @returns
 */
export function getPluginInfo(params) {
  return api.get('/plugin/api/get_plugin_info/', { params })
}

// 判断是否已经购买过应用
export function getIsAlreadyBuyPlugin(data) {
  return api.post('/plugin/api/is_already_buy_plugin/', data)
}

export function getProjectSetting(projectId, params) {
  return api.get(`/project/api/setting/${projectId}/`, { params })
}

// 获取跳转 人工评分页 所需的seq
export function httpGetSeqVersion(projectId) {
  return api.get(`/report/api/scoring/rspd_seq/${projectId}/`)
}

/**
 * 导出项目
 * @param {string} pid
 * @param {boolean} answer_analysis:测评导出是否带分数
 * @returns
 */
export function exportProject(pid, answer_analysis) {
  // content-type: application/x-www-form-urlencoded
  return api.post(
    `/report/docx/content?pid=${pid}`,
    {
      proj_id: pid,
      answer_analysis,
      __FORMDATA__: true,
    }
    // {
    //   responseType: 'blob',
    //   timeout: 30000,
    // }
  )
}

/**
 * 轮询导出状态
 * @param {string} pid
 * @returns
 */
export function getAsyncExportStatus(pid) {
  return api.get(`/report/docx/export_project_status/?project_id=${pid}`)
}

/**
 * 清除项目数据
 * @param {string} pid
 * @returns
 */
export function clearProject(pid) {
  return api.post(`/report/ajax/cleanup_project/?pid=${pid}`, {
    proj_id: pid,
  })
}

// 更新项目状态
export function httpUpdateProjectStatus(params) {
  return api.post('/edit/api/update_project_status/', params)
}

export function httpUpdateAjaxProjectStatus(params) {
  return api.post('/edit/ajax/update_project_status/', { ...params, __FORMDATA__: true })
}

// 更新飞行棋项目状态
export function httpUpdateFlyingChessStatus(projectId, data) {
  return api.post(`/flying_chess/api/projects/publish_status/${projectId}`, data)
}

// 是否归档
export function httpProjectArchive(projectId, data) {
  return api.post(`/report/ajax/project_archive/?pid=${projectId}`, { ...data, __FORMDATA__: true })
}

/**
 * 重命名项目
 * @param {string} pid
 * @param {string} title
 * @returns
 */
export function renameProject(pid, title) {
  return api.post(
    `/api/edit/internal/update_project_info/${pid}/`,
    {
      title,
    },
    {
      __SIGNATURE__: signatureFunc_new,
    }
  )
}

/**
 * 删除项目
 * @param {{
 *  proj_id: string;
 *  folder_id?: string;
 * }} data
 * @returns
 */
export function deleteProject({ proj_id, folder_id }) {
  return api.post('/edit/ajax/del_project', {
    proj_id,
    folder_id,
    __FORMDATA__: true,
  })
}

/**
 * 退出外部项目协作
 * @param {string} pid
 * @returns
 */
export function quitCooperation(pid) {
  return api.post('/concert/api/v1/user/quit', {
    resource_type: 0,
    resource_id: pid,
  })
}

/**
 * 检查360项目是否可编辑
 * @param {string} pid
 * @returns
 */
export function checkAppraiseStatus(pid) {
  return api.get(`/edit/api/async_process_check/${pid}/`, {
    params: {
      operation: 'appraise',
    },
  })
}

// 切换网格和列表
export function editListSetting(params) {
  return api.get('/edit/ajax/list/settings', { params })
}

// 获取我的项目列表
export function getProjectList(params) {
  // /edit/ajax/list/project_list/
  return api.get('/api/project/list/', { params })
}

/**
 *
 * @param {Boject} params
 * @description : 外部协作的接口沿用老接口格式
 * @returns
 */
export const getCooperationList = ({ create_type, ...surplus }) =>
  $get('/api/concert/list/', {
    ...surplus,
  })
/**
 * 置顶/取消置顶文件夹
 * @param {string} folder_id
 * @param {boolean} is_top false取消置顶；true置顶
 * @returns
 */
export function topFolder(folder_id, is_top) {
  return api.post('/api/folder/top/update/', {
    folder_id,
    is_top,
  })
}

/**
 * 新建文件夹
 * @param {string} folder_name
 * @returns
 */
export function newFolder(folder_name) {
  return api.post('/api/folder/folder/create/', {
    folder_name,
  })
}

/**
 * 重命名文件夹
 * @param {string} folder_id
 * @param {string} folder_name
 * @returns
 */
export function renameFolder(folder_id, folder_name) {
  return api.post('/api/folder/name/update/', {
    folder_name,
    folder_id,
  })
}

/**
 * 删除文件夹
 * @param {string} folder_id
 * @param {boolean} shouldDeleteProjects 是否删除内部的所有项目
 * @returns
 */
export function deleteFolder(folder_id, shouldDeleteProjects) {
  return api.post('/api/folder/folder/delete/', {
    folder_id,
    del_project: shouldDeleteProjects,
  })
}

/**
 * 移动项目进/出文件夹
 * @param {'in' | 'out'} direct
 * @param {{
 *  project_id: string;
 *  folder_id: string;
 *  direct: boolean;
 * }} data direct: true移入；false移出
 */
export function moveProject(data) {
  return api.post('/api/folder/project/move/', data)
}

//  新2024年文件夹列表
export function getHttpAllFolder() {
  return api.get('/api/project/folders/')
}

// 判断是否有未编辑完成的题目
export function publishCheck(projectId = '') {
  return api.get(`/edit/ajax/publish_check/${projectId}/`)
}

// 获取通知banner数据
export function announcements() {
  return api.get('/announcements')
}

/**
 *
 * @returns {
 *  is_enterprise_admin:0 不是企业主 1:企业主
 *  space_type:1 个人空间 2: 企业空间
 * }
 *
 *
 * }全局变量配置属性
 */
export const listData = () => api.get('/list/data/')

// 保存选中的欢迎语标签
export const saveUserTag = (data) => api.post('/member/api/tag/management/', data)

// 关闭欢迎语标签弹框，保存为已打开过
export const closeUserTag = (data) => api.post('/member/api/tag/cancel/', data)

// 获取用户标签
export function httpUserTag() {
  return api.get('/member/api/tag/list/')
}

// 音视频签署协议
export function httpPostSignAgreement(projectId, postData) {
  return api.post(`/edit/api/sign_agreement/${projectId}/`, postData)
}

/* 获取在线弹框信息 */
export function httpOnlinePopupInfo(params) {
  return api.get('/popup/display/', { params })
}

/**
 * @method 获取上传文件到七牛的token
 */
export function httpGetUploadQiniuToken(params) {
  return api.get(`/edit/api/upload/qiniu_token/${params.pid}/`, { params })
}

// 获取签署信息、上次填写内容
export function httpGetSignAgreement(projectId, params) {
  return api.get(`/edit/api/sign_agreement/${projectId}/`, { params })
}

// 360场景，收集到数据暂停项目时，需要调报表自动下载的接口
export function getDownloadPreRequest(pid, params) {
  return api.get(`/report/api/download/pre_request/${pid}`, { params })
}

/**
 * 添加项目到快速访问
 * @param {string} project_id
 * @returns
 */
export function addQuickAccess(project_id) {
  return api.post('/api/project/quick_access/add', { project_id })
}

/**
 * 从快速访问中移除项目
 * @param {string} project_id
 * @returns
 */
export function quitQuickAccess(project_id) {
  return api.post('/api/project/quick_access/remove', { project_id })
}

/**
 * 获取快速访问项目列表
 * @param {*} params
 * @returns
 */
export function quickAccessList({ create_type, ...params }) {
  return api.get('/api/project/quick_access/get', { params })
}

/**
 * @description 是不是显示广告位置
 * @param {*} data
 * @returns
 */
export function getGuideEntrance() {
  return $get('/api/list_page_entrance/guide_entrance/')
}

/**
 * @description 获取答题数据
 * @param {*} params 为答题的shortid组合
 * @returns
 */
export function getProjectDataView(params) {
  return api.get('/api/rspd/count/info', { params })
}

export function getCopyShow(params) {
  return api.get(`/api/edit/get_hasown_attachment/${params.pid}/`, {
    __SIGNATURE__: signatureFunc_new,
  })
}
/**
 * @description 投票2.0，刷新报名数据
 * @param {*} pid 项目ID
 * @returns
 */
export function refreshSignUp(pid) {
  return api.post(
    `/api/edit/sign_up_child_project/refresh/${pid}/`,
    {},
    {
      __SIGNATURE__: signatureFunc_new,
    }
  )
}

/**
 * 判断是不是钉钉成员还是企业
 */
export function httpDingTalkState() {
  return $get('/api/member/third_part/dingtalk/auth-type')
}

/**
 * @method  获取已创建订单
 */
export function httpGetPconvertList(params) {
  return api.get(`/api/sample_collection/get_list/`, { params })
}

// 获取 项目题目总数与是不是复杂提醒设计
/**
 *  
 * @returns Prmoise<{data:{
 *           has_complex_setting": true,// 是不是复杂题目
             question_count": 1, // 题目数量
             is_special_member": false
 * }}>
 */
export function getProjectPcovert(pid) {
  return api.get(`/api/edit/pcovert/project_info/${pid}/`, {
    __SIGNATURE__: signatureFunc_new,
  })
}
