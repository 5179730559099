import api from '~/api/base'

// 从server或client获取cookie字段，并更新store的cookie模块
export default function ({ req, store }) {
  let reqCookie = ''
  // 需要兼容static构建
  if (process.server && !process.static) {
    const { headers } = req
    reqCookie = headers.cookie
    // 只有服务端需要拼接cookie
    api.defaults.headers.common.cookie = reqCookie
  }
  if (process.client) {
    reqCookie = document.cookie
  }
  store.commit('cookie/parse', reqCookie)
}
