import { getUserInfo } from '~/api/common.js'

// 需要登录校验的路由地址array
export const requiresUserAuthPath = [
  '/list',
  '/contact/email/send',
  '/contact/sms/send',
  '/contact/merge',
  '/contact/show_history',
  '/contact/sms/detail',
  '/contact/show_history/statistic',
  '/contact/show_history/content',
  '/plugin',
  '/plugin/index',
  '/inner_collect_post',
]
let requiresUserAuth = false

// 在服务端页面渲染前，获取登录态，客户端渲染不需要重复操作
export default async function ({ route: { path, fullPath }, store, redirect }) {
  if (process.server && !process.static) {
    try {
      // 检测是否需要用户登录
      requiresUserAuth = requiresUserAuthPath.find((rulePath) => path.includes(rulePath))
      // 仅当未登录状态才发请求
      if (store.state.loginStatus.loggedIn === 0) {
        const { data } = await getUserInfo()
        store.commit('setLoginStatus', { loggedIn: 1, form: 'serveTask' })
        store.commit('setUserInfo', { _init: true, ...data })
      }
    } catch (error) {
      //   console.log('--- 获取用户登录信息失败 --> ', error)
      // 判断是否需要登录校验
      if (requiresUserAuth) {
        redirect(`/login?nexturl=${encodeURIComponent(fullPath)}&from=middleware`)
      }
    }
  }
}
